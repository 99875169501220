import React, { useState, useEffect, useRef } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { HiMiniEyeSlash } from "react-icons/hi2";
import { useNavigate, useLocation } from "react-router-dom";
import Loader2 from "../../components/Loaders/Loader2";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import "./Register.css";
import AlertYellow from "../../components/Alerts/AlertYellow.jsx";
import AlertGreen from "../../components/Alerts/AlertGreen.jsx";
import AlertRed from "../../components/Alerts/AlertRed.jsx";

function Register() {
  const location = useLocation();
  const { isAuthenticated } = CheckAuth();
  const gemail = location?.state?.email;
  const gname = location?.state?.name;
  const galert = location?.state?.alert;
  const [email, setEmail] = useState(gemail !== undefined ? gemail : "");
  const [a, setA] = useState(false);
  const [emailOtp, setEmailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [otp, setOtp] = useState();
  const [pass, setPass] = useState("");
  const [confirmpass, setConfirmpass] = useState("");
  const [fullName, setFullName] = useState(gname !== undefined ? gname : "");
  const [phone, setPhone] = useState("");
  const [generatedEmailOtp, setGeneratedEmailOtp] = useState("");
  const [emailVerified, setEmailVerified] = useState(0);
  const [phoneVerified, setPhoneVerified] = useState(0);
  const [generatedPhoneOtp, setGeneratedPhoneOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [loader, setLoader] = useState(false);
  const [resendTimer, setResendTimer] = useState(90);
  const [resendPhoneTimer, setResendPhoneTimer] = useState(90);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(galert !== undefined ? 7 : -1);
  const [message, setMessage] = useState("");
  const navigateTimeout = useRef(null);

  const handleNavigate = () => {
    if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    navigate("/login");
  };

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
      navigateTimeout.current = setTimeout(handleNavigate, 3000);
    }
    return () => {
      if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    };
  }, [message]);

  const handleResendTimer = () => {
    setGeneratedEmailOtp("");
    setResendTimer(90);

    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const handleResendPhoneTimer = () => {
    setGeneratedPhoneOtp("");
    setResendPhoneTimer(90);

    const timerInterval = setInterval(() => {
      setResendPhoneTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const verifyEmailOtp = (e) => {
    e.preventDefault();
    if (generatedEmailOtp === emailOtp) {
      setEmailVerified(1);
    } else {
      setShowModal(0);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
      setEmailVerified(2);
    }
  };

  const verifyPhoneOtp = (e) => {
    e.preventDefault();
    if (phoneOtp === otp) {
      setPhoneVerified(1);
    } else {
      setShowModal(6);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
      setPhoneVerified(2);
    }
  };

  const sendEmailOtp = (e) => {
    e.preventDefault();
    fetch(`https://api-scdndestates.com/api/register/send-email-otp/`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send otp");
        }
        return response.json();
      })
      .then((response) => {
        handleResendTimer();
        setGeneratedEmailOtp(response.data);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  const sendPhoneOtp = (e) => {
    e.preventDefault();
    if (phone.length !== 10) {
      setShowModal(1);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
    } else {
      fetch(`https://api-scdndestates.com/api/register/send-phone-otp/`, {
        method: "POST",
        body: JSON.stringify({
          phone: "91" + phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send otp");
          }
          return response.json();
        })
        .then((response) => {
          var dataObject = JSON.parse(response.msg91_response);
          setOtp(response.otp);
          var dataArray = [dataObject];
          handleResendPhoneTimer();
          setGeneratedPhoneOtp(dataArray[0].type);
          // Handle the received data here
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const handleRegister = (e) => {
    setLoader(true);
    e.preventDefault();
    if (pass !== confirmpass) {
      setShowModal(2);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
      setLoader(false);
    } else if (emailVerified !== 1 && gemail === undefined) {
      setShowModal(3);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
      setLoader(false);
    } else if (phoneVerified !== 1) {
      setShowModal(4);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
      setLoader(false);
    } else if (a !== true) {
      setShowModal(5);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
      setLoader(false);
    } else {
      fetch(`https://api-scdndestates.com/api/register/`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: pass,
          fullName: fullName,
          phone: phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to login");
          }
          return response.json();
        })
        .then((response) => {
          setMessage(response.data);
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  useEffect(() => {
    const accessToken = getCookie("access_token");
    if (isAuthenticated || accessToken) {
      const timer = setTimeout(() => {
        navigate("/");
      }, 3000);
      return () => clearTimeout(timer);
    }
    setTimeout(() => {
      setShowModal(-1);
    }, 3000);
  }, [isAuthenticated]);

  const [errorMessage, setErrorMessage] = useState("");

  const validate = (value) => {
    setPass(value);

    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&])[A-Za-z\d!@#$%^&]{8,}$/;

    if (strongPasswordRegex.test(value)) {
      if (value.length >= 8) {
        setErrorMessage(
          <span className="text-success text-sm font-semibold">
            Strong Password
          </span>
        );
        setA(true);
      } else {
        setErrorMessage(
          <span className="text-warning text-sm font-semibold">
            Medium Password
          </span>
        );
        setA(true);
      }
    } else {
      setErrorMessage(
        <span className="text-danger font-size1">
          Password must have at least 8 characters, including at least 1
          lowercase character, 1 uppercase character, 1 number, and 1 special
          character from (@#$%^&*)
        </span>
      );
      setA(false);
    }
  };

  const handlephone = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length < 11) {
      if (/^\d*$/.test(inputValue)) {
        setPhone(inputValue);
      }
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Allow only numeric values
    if (/^\d*$/.test(inputValue)) {
      setEmailOtp(inputValue); // Update state only if the input is valid
    }
  };

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;

    // Allow only numeric values
    if (/^\d*$/.test(inputValue)) {
      setPhoneOtp(inputValue); // Update state only if the input is valid
    }
  };

  return (
    <>
      {loader && <Loader2 />}
      <div className="bg-fixed flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 md:mt-4">
        <div className="w-full mx-auto">
          <a href="/">
            <img
              className="mx-auto h-16 w-auto"
              src="/images/logo01.png"
              alt="Your Company"
            />
          </a>
          <h2 className="mt-8 md:mt-10 text-center text-xl md:text-2xl font-bold leading-9 tracking-tight text-[#212121]">
            Welcome to SCDND Estates
          </h2>
          <h2 className="md:mt-2 text-center text-lg md:text-xl font-bold leading-9 tracking-tight text-[#212121]">
            Get Started
          </h2>
        </div>

        <div className="mt-8 md:mt-10 sm:mx-auto sm:w-full sm:max-w-sm ">
          <form className="space-y-6" onSubmit={handleRegister}>
            <div className=" justify-center">
              <div>
                <label
                  htmlFor="fullname"
                  className="block text-sm font-semibold leading-4 text-[#212121]"
                >
                  Enter your full Name
                </label>
                <div className="mt-2">
                  <input
                    value={fullName}
                    name="fullName"
                    className="px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6 "
                    onChange={(e) => setFullName(e.target.value)}
                    id="fullName"
                    placeholder="First Middle Last"
                    required
                  />
                </div>
              </div>
              <div className="mt-3">
                <div className="flex justify-between">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-4 text-[#212121]"
                  >
                    Enter your Email ID
                  </label>
                  <div className="text-sm ">
                    {gemail === undefined && emailVerified !== 1 && (
                      <>
                        {0 < resendTimer && resendTimer < 90 ? (
                          <div className="font-semibold text-[#5ab2ff] hover:text-[#3aa0ff] duration-300 ease-in-out">
                            Resend in {resendTimer}s
                          </div>
                        ) : (
                          <button
                            className="focus:animate-pulse font-semibold text-[#5ab2ff] hover:text-[#3aa0ff] duration-300 ease-in-out"
                            onClick={sendEmailOtp}
                          >
                            Send OTP
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className=" flex items-center mt-2">
                  {emailVerified === 1 ||
                  generatedEmailOtp !== "" ||
                  gemail !== undefined ? (
                    <>
                      <input
                        value={gemail !== undefined ? gemail : email}
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#DBEDF9] text-sm sm:leading-6"
                        type="email"
                        placeholder="youremail"
                        id="email"
                        name="email"
                        disabled
                      />
                    </>
                  ) : (
                    <>
                      <input
                        value={email}
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#DBEDF9] text-sm sm:leading-6"
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="youremail"
                        id="email"
                        name="email"
                        required
                      />
                    </>
                  )}
                </div>
                {(generatedEmailOtp !== "" || gemail !== undefined) && (
                  <>
                    <div className="flex justify-between items-center mt-2">
                      <div className="col-10 ">
                        <input
                          value={gemail !== undefined ? "*****" : emailOtp}
                          onChange={handleInputChange}
                          type="text"
                          className={`px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6 ${
                            emailVerified === 2
                              ? "border-danger border-2"
                              : "border-dark"
                          }`}
                          placeholder="Enter OTP"
                          id="emailOtp"
                          name="emailOtp"
                          required
                          disabled={emailVerified === 1}
                        />
                      </div>
                      <div className=" ">
                        {gemail === undefined && emailVerified === 0 && (
                          <>
                            <button
                              className="text-sm font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]"
                              onClick={verifyEmailOtp}
                            >
                              Verify
                            </button>
                          </>
                        )}
                        {(emailVerified === 1 || gemail !== undefined) && (
                          <svg
                            width="30px"
                            height="30px"
                            viewBox="-5 -5 93 93"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <circle
                                id="oval"
                                stroke="#43ac6a"
                                strokeWidth="9"
                                fill="transparent"
                                cx="41.5"
                                cy="41.5"
                                r="41.5"
                              ></circle>
                              <polygon
                                id="tick"
                                fill="#43ac6a"
                                points="66 29.01 35.03 59.97 16 40.94 22.92 34.02 35.03 46.13 59.08 22.09"
                              ></polygon>
                            </g>
                          </svg>
                        )}
                        {emailVerified === 2 && (
                          <>
                            <button
                              className="text-sm font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]"
                              onClick={verifyEmailOtp}
                            >
                              Verify
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="mt-3">
                <div className="flex justify-between">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-semibold leading-4 text-[#212121]"
                  >
                    Enter your Phone Number
                  </label>
                  <div className="text-sm ">
                    {phoneVerified !== 1 && (
                      <>
                        {0 < resendPhoneTimer && resendPhoneTimer < 90 ? (
                          <div className="font-semibold text-[#5ab2ff] hover:text-[#3aa0ff] duration-300 ease-in-out">
                            Resend in {resendPhoneTimer}s
                          </div>
                        ) : (
                          <button
                            className="focus:animate-pulse font-semibold text-[#5ab2ff] hover:text-[#3aa0ff] duration-300 ease-in-out"
                            onClick={sendPhoneOtp}
                          >
                            Send OTP
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className=" flex items-center mt-2">
                  <div className="input-group m-0 p-0 row ">
                    <input
                      value={phone}
                      onChange={handlephone}
                      className="px-2 block col-12 rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6"
                      type="text"
                      placeholder="Ten digit number"
                      id="phone"
                      name="phone"
                      required
                      disabled={generatedPhoneOtp !== ""}
                    />
                  </div>
                </div>
                {generatedPhoneOtp === "success" && (
                  <>
                    <div className="flex justify-between items-center mt-2">
                      <div className="col-10 ">
                        <input
                          value={phoneOtp}
                          onChange={handlePhoneInputChange}
                          type="text"
                          className={`px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6 ${
                            phoneVerified === 2
                              ? "border-danger border-2"
                              : "border-dark"
                          }`}
                          placeholder="Enter OTP"
                          id="phoneOtp"
                          name="phoneOtp"
                          required
                          disabled={phoneVerified === 1}
                        />
                      </div>
                      <div className=" ">
                        {phoneVerified === 0 && (
                          <>
                            <button
                              className="text-sm font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]"
                              onClick={verifyPhoneOtp}
                            >
                              Verify
                            </button>
                          </>
                        )}
                        {phoneVerified === 1 && (
                          <svg
                            width="30px"
                            height="30px"
                            viewBox="-5 -5 93 93"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <circle
                                id="oval"
                                stroke="#43ac6a"
                                strokeWidth="9"
                                fill="transparent"
                                cx="41.5"
                                cy="41.5"
                                r="41.5"
                              ></circle>
                              <polygon
                                id="tick"
                                fill="#43ac6a"
                                points="66 29.01 35.03 59.97 16 40.94 22.92 34.02 35.03 46.13 59.08 22.09"
                              ></polygon>
                            </g>
                          </svg>
                        )}
                        {phoneVerified === 2 && (
                          <>
                            <button
                              className="text-sm font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]"
                              onClick={verifyPhoneOtp}
                            >
                              Verify
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="mt-3">
                <div className="">
                  <label className="newpassword block text-sm font-semibold leading-4 text-[#212121]">
                    Enter your New Password:
                  </label>
                </div>

                <div className="mt-2 ">
                  <div className="flex">
                    <input
                      value={pass}
                      onChange={(e) => validate(e.target.value)}
                      type={`${showPassword === false ? "password" : "text"}`}
                      placeholder="New Password"
                      id="password"
                      name="password"
                      className="px-2 block w-full rounded-md border-0 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#DBEDF9] text-sm sm:leading-6"
                      required
                    />
                    <div
                      className="px-2 flex align-items-center "
                      id="basic-addon1"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword === false ? (
                        <HiMiniEyeSlash size={22} />
                      ) : (
                        <IoEyeSharp size={22} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <div className="">
                  <label className="confirmpassword block text-sm font-semibold leading-4 text-[#212121]">
                    Confirm your New Password:
                  </label>
                </div>

                <div className="mt-2 ">
                  <div className="flex">
                    <input
                      type={`${showPassword1 === false ? "password" : "text"}`}
                      value={confirmpass}
                      onChange={(e) => setConfirmpass(e.target.value)}
                      placeholder="Confirm password"
                      id="confirmpassword"
                      name="confirmpassword"
                      className="px-2 block w-full rounded-md border-0 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#DBEDF9] text-sm sm:leading-6"
                      required
                    />
                    <div
                      className="px-2 flex align-items-center "
                      id="basic-addon1"
                      onClick={() => setShowPassword1(!showPassword1)}
                    >
                      {showPassword1 === false ? (
                        <HiMiniEyeSlash size={22} />
                      ) : (
                        <IoEyeSharp size={22} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 my-1">
              {errorMessage === "" ? null : <>{errorMessage}</>}
            </div>

            <div className="mb-4 d-flex justify-content-center">
              <button
                className="flex w-full justify-center rounded-md bg-[#5ab2ff] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                type="submit"
              >
                Register
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already have an account?{" "}
            <a
              href="/login"
              className="font-semibold leading-6 text-[#5ab2ff] hover:text-[#3aa0ff]"
            >
              Login Here
            </a>
          </p>
        </div>
      </div>
      {/* Modal for showing alert */}
      {showModal === 0 && (
        <AlertRed
          message="Email is not verified. Please enter correct OTP!"
          onClose={() => setShowModal(-1)}
        />
      )}
      {showModal === 1 && (
        <AlertRed
          message="Mobile number must be 10 digits"
          onClose={() => setShowModal(-1)}
        />
      )}
      {showModal === 2 && (
        <AlertRed
          message="Passwords do not match, please re-enter your password."
          onClose={() => setShowModal(-1)}
        />
      )}
      {showModal === 3 && (
        <AlertYellow
          message="Your Email is not OTP verified yet"
          onClose={() => setShowModal(-1)}
        />
      )}
      {showModal === 4 && (
        <AlertYellow
          message="Your phone is not OTP verified yet"
          onClose={() => setShowModal(-1)}
        />
      )}
      {showModal === 5 && (
        <AlertGreen
          message="Please check your password characters again"
          onClose={() => setShowModal(-1)}
        />
      )}
      {showModal === 6 && (
        <AlertRed
          message="Phone is not verified. Please enter correct OTP!"
          onClose={() => setShowModal(-1)}
        />
      )}
      {showModal === 7 && (
        <AlertRed message={galert} onClose={() => setShowModal(-1)} />
      )}
      {message === "User already registered, please try to Login" ? (
        <AlertYellow
          message="User already registered, please Login to continue!"
          onClose={() => {
            setMessage("");
            handleNavigate();
          }}
        />
      ) : (
        message ===
          "Successfully registered the User, please Login to continue" && (
          <AlertGreen
            message="Successfully registered the User, please Login to continue."
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        )
      )}
    </>
  );
}

export default Register;
