import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loader1 from "../Loaders/Loader1";
import Loader2 from "../Loaders/Loader2";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";
import secureLocalStorage from "react-secure-storage";
import { FaLongArrowAltRight } from "react-icons/fa";
import AlertRed from "../Alerts/AlertRed.jsx";
import AlertBlue from "../Alerts/AlertBlue.jsx";
import AlertYellow from "../Alerts/AlertYellow.jsx";
import AlertGreen from "../Alerts/AlertGreen.jsx";

function Signup_Mode() {
  const { isAuthenticated, email, pan, phone } = CheckAuth();
  const { loader, statusG } = UserData({
    isAuthenticated,
    email,
    phone,
    pan,
    formName: "",
  });
  const paraRef = useRef(null);
    const [isAtBottom, setIsAtBottom] = useState(true);
  const [y, setY] = useState("");
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [agencyCode, setAgencyCode] = useState("");
  const [check, setCheck] = useState(false);
  const [status, setStatus] = useState(0);
  const navigate = useNavigate();
  const storedData = secureLocalStorage.getItem("kycData");
  const userKycData = JSON.parse(storedData);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const navigateTimeout = useRef(null);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [response2, setresponse2] = useState(null);

  const handleScroll = () => {
    const element = paraRef.current;
    if (element) {
      const isScrolledToBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight;
      setIsAtBottom(isScrolledToBottom);
    }
  };

  useEffect(() => {
    const element = paraRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => element.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const handleNavigate = () => {
    if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    if (response2["path"] !== undefined) {
      secureLocalStorage.setItem("kycData", JSON.stringify(response2.data));
      if (response2["path"] === "Edit") {
        navigate("/profile");
      } else {
        logout();
      }
    }
  };

  const handleNavigate2 = () => {
    if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    window.location.href = "/userLost";
  };

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
      navigateTimeout.current = setTimeout(handleNavigate, 3000);
    }
    return () => {
      if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    };
  }, [message]);

  useEffect(() => {
    if (message2 !== "") {
      setTimeout(() => {
        setMessage2("");
      }, 3000);
      navigateTimeout.current = setTimeout(handleNavigate2, 3000);
    }
    return () => {
      if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    };
  }, [message2]);

  const handleY = (e) => {
    setY(e.target.value);
    if (e.target.value === "direct") {
      setShowModal1(true);
      setShowModal2(false);
      setShowModal3(false);
      setTimeout(() => {
        setShowModal1(false);
      }, 5000);
      setAgencyCode("000");
    } else if (e.target.value === "assisted") {
      setShowModal1(false);
      setShowModal2(true);
      setShowModal3(false);
      setTimeout(() => {
        setShowModal2(false);
      }, 5000);
      setAgencyCode("");
    }
  };

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  // logout function
  const logout = () => {
    setLoader4(true);
    const refreshToken = getCookie("refresh_token");
    secureLocalStorage.clear();
    if (refreshToken) {
      fetch(`https://api-scdndestates.com/api/logout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to logout");
          }
          return response.json();
        })
        .then((response) => {
          document.cookie =
            "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          if (response.error === "Logged out successfully") {
            const currentUrl = new URL(window.location.href);
            if (currentUrl.pathname === "/") {
              window.location.reload();
            } else {
              window.location.href = "/";
            }
          } else {
            setMessage2(response.error);
          }
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    } else {
      setLoader4(false);
      setShowModal1(false);
      setShowModal2(false);
      setShowModal3(true);
      setTimeout(() => {
        setShowModal3(false);
        window.location.href = "/accessDenied";
      }, 5000);
    }
  };
  // logout function

  const handleRegister = (e) => {
    e.preventDefault();
    setLoader3(true);
    if (!isAuthenticated) {
      navigate("/userLost");
    } else {
      fetch(`https://api-scdndestates.com/api/user-kyc/`, {
        method: "POST",
        body: JSON.stringify({
          form_name: "signupMode",
          email: email,
          phone: phone,
          pan: pan,
          signupMode: y,
          agencyCode: agencyCode,
          disclosure: check,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to login");
          }
          return response.json();
        })
        .then((response) => {
          setMessage(response["error"]);
          setresponse2(response);
          setLoader3(false);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setY(userKycData?.signupMode);
    setAgencyCode(userKycData?.agencyCode);
    if (userKycData?.["KYC_status"]["G"] == 10) {
      setStatus(1);
      setCheck(true);
    } else if (userKycData?.["KYC_status"]["G"] === 5) {
      setStatus(2);
    } else {
      setStatus(0);
    }
  }, [statusG]);

  return (
    <>
      {loader4 && <Loader2 />}
      {loader ? (
        <Loader1 />
      ) : (
        <>
          <form action="" onSubmit={handleRegister} className="font-size1">
            <div className="border rounded py-2 mt-3">
              <div className="flex flex-wrap justify-between mx-auto px-2 ">
                <p className="p-2 pb-0 text-secondary">
                  <div className="list-unstyled para-style2 font-size1 ">
                    <p className="mb-1  flex items-start text-xs md:text-sm text-[#616161] font-semibold">
                      <span>
                        <FaLongArrowAltRight className="mt-1 mr-2" />
                      </span>
                      You can buy Decentralized Assets only after your KYC is
                      100% complete.
                    </p>
                    <p className="mb-1  flex items-start text-xs md:text-sm text-[#616161] font-semibold">
                      <span>
                        <FaLongArrowAltRight className="mt-1 mr-2" />
                      </span>
                      Please note that you will be charged between 0.1%-0.5% of
                      your INITIAL INVESTMENT AMOUNT as Onboarding Fee, if you
                      have enrolled on this platform.
                    </p>
                    <p className="mb-1 flex items-start text-xs md:text-sm text-[#616161] font-semibold">
                      <span>
                        <FaLongArrowAltRight className="mt-1 mr-2" />
                      </span>
                      <div className="block">
                        The Onboarding Fee is charged only for the{" "}
                        <b>First Year</b>. All future investments under the same
                        PAN, whether Direct or Assisted,will entail Zero
                        Onboarding Fee from the second year onwards.
                      </div>
                    </p>
                    {/* <div className="d-flex align-items-center justify-content-center"> */}
                    <div className="text-dark py-2 mb-2">
                      <div className="mb-1  flex items-start text-xs md:text-sm text-[#616161] font-bold">
                        <span>
                          <FaLongArrowAltRight className="mt-1 mr-2" />
                        </span>
                        Money wallet Recharge onboarding fee:
                      </div>
                      <div className="row m-0 p-0 ">
                        <span
                          className="w-[30%] lg:w-[10%]  "
                          style={{ backgroundColor: "#e4a11b9c" }}
                        >
                          10L-20L
                        </span>
                        <span className="w-[25%] lg:w-[10%]">0.5%</span>
                        <hr className="mb-1 m-0 d-inline-block text-light" />
                        <span
                          className="w-[35%] lg:w-[20%] "
                          style={{ backgroundColor: "#ff4f0087" }}
                        >
                          20L - 50L
                        </span>
                        <span className="w-[25%] lg:w-[10%]">0.4%</span>
                        <hr className="mb-1 m-0 d-inline-block text-light" />
                        <span
                          className="w-[50%] lg:w-[30%]"
                          style={{ backgroundColor: "#dc4c64ba" }}
                        >
                          50L - 1Cr
                        </span>
                        <span className="w-[25%] lg:w-[10%]">0.3%</span>
                        <hr className="mb-1 m-0 d-inline-block text-light" />
                        <span
                          className="w-[65%] lg:w-[40%]"
                          style={{ backgroundColor: "#dc35b28c" }}
                        >
                          1Cr - 5Cr
                        </span>
                        <span className="w-[25%] lg:w-[10%]">0.2%</span>
                        <hr className="mb-1 m-0 d-inline-block text-light" />
                        <span
                          className="w-[80%] lg:w-[50%] ary"
                          style={{ backgroundColor: "#3b71ca7a" }}
                        >
                          &gt;5 Crore
                        </span>
                        <span className="w-[20%] lg:w-[10%] px-2">0.1%</span>
                      </div>
                      {/* </div> */}
                    </div>
                    <hr />
                  </div>
                </p>
              </div>
              <div className="flex flex-wrap justify-between mx-auto px-3">
                <div className="w-full lg:w-[49%] mt-2">
                  <h6 className="text-sm font-bold mb-2">
                    How are you starting your journey towards decentralized
                    assets?
                    <span className="text-danger">*</span>
                  </h6>
                  <div className="flex">
                    <div className="form-check form-check-inline flex items-center">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="signUpMode"
                        id="signUpMode1"
                        value="direct"
                        checked={y === "direct"}
                        disabled={status === 1}
                        onChange={handleY}
                      />
                      <label
                        className="form-check-label "
                        htmlFor="signUpMode1"
                      >
                        Direct
                      </label>
                    </div>
                    <div className="form-check form-check-inline flex items-center">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="signUpMode"
                        id="signUpMode2"
                        value="assisted"
                        checked={y === "assisted"}
                        disabled={status === 1}
                        onChange={handleY}
                      />
                      <label className="form-check-label" htmlFor="signUpMode2">
                        Assisted
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-[49%] my-2">
                  <>
                    <label
                      className="font-semibold text-[#212121]"
                      htmlFor="agencyCode2"
                    >
                      Agency Code<span className="text-danger">*</span>
                    </label>
                    {y === "direct" ? (
                      <input
                        className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                        type="text"
                        id="agencyCode2"
                        name="agencyCode2"
                        value="000"
                        disabled={status === 1}
                      />
                    ) : (
                      <input
                        className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                        type="text"
                        id="agencyCode2"
                        name="agencyCode2"
                        placeholder="Please Enter agent code"
                        value={agencyCode}
                        disabled={status === 1}
                        onChange={(e) => setAgencyCode(e.target.value)}
                      />
                    )}
                  </>
                </div>
                <div className="w-full mt-2 p-0">
                  <div
                    className="rounded-xl fatca-box"
                    ref={paraRef}
                    style={{ maxHeight: "150px", overflowY: "auto" }}
                  >
                    <p className="m-0 px-2 font-medium text-xs leading-4 my-1">
                      By accessing and using this website (the “Website”) and
                      any web page hereof, you agree to be bound by the terms
                      and conditions below. Reference to these general terms of
                      use should be made each time you access and use the
                      Website.
                      <ol type="1" className="font-size2 mb-1">
                        <li>
                          The contents of this Website are provided to you for
                          general information only and should not be used as a
                          recommendation or basis for making any specific
                          investment, business or commercial decision.
                        </li>
                        <li>
                          Investments are subject to market risks including the
                          possible loss of the principal amount invested. The
                          value of the securities/commodities/currencies or any
                          other financial instrument may fall as well as rise.
                          If the investment is denominated in a foreign
                          currency, factors including but not limited to changes
                          in exchange rates may have an adverse effect on the
                          value, price or income of an investment. Past
                          performance figures as well as any projection or
                          forecast used in these web-pages, are not necessarily
                          indicative of future performance of any assets or
                          investment products.
                        </li>
                        <li>
                          The information contained in these pages is not
                          intended to provide professional advice and should not
                          be relied upon in that regard. It also does not have
                          any regard to your specific investment objective,
                          financial situation, risk tolerance and any of your
                          particular needs.
                        </li>
                        <li>
                          Upon registering for our services, users are subject
                          to an initial one-time <b>Onboarding Fee</b>. This fee
                          will be assessed as a&nbsp;
                          <b>
                            percentage of the total funds credited into the
                            Money Wallet, throughout the entire first year
                          </b>
                          &nbsp;of relationship with SCDND Estates. After the
                          initial year is over, the Onboarding Fee will be
                          permanently discontinued for the User. By utilizing
                          our services and agreeing to these terms, the User
                          acknowledges and consents to the Onboarding Fee
                          structure outlined above. The percentage rate
                          applicable to the Onboarding Fee shall be clearly
                          communicated to Users upon Registration &amp; KYC.{" "}
                          <br />
                          Users further understand that failure to pay the said
                          fee may result in restricted access to certain
                          features or termination of their account. Any disputes
                          regarding the same shall be resolved in accordance
                          with the terms outlined in our Terms of Service.
                        </li>
                        <li>
                          We reserve the right to modify, suspend, or terminate
                          the Onboarding Fee policy at our discretion, with or
                          without notice, and such changes will be effective
                          immediately upon posting on our Website.
                        </li>
                        <li>
                          While accessing our services, the Customer will be
                          subject to a Client Relationship Fee, which will be
                          charged as a{" "}
                          <b>percentage of Total Assets under Management</b>.
                          This fee will be charged on a recurring basis at the
                          end of every quarter. Both the Client Relationship Fee
                          and any other applicable fees will be charged by
                          debiting the User&#39;s Money Wallet on the designated
                          payment date. It is the User&#39;s responsibility to
                          ensure that there are sufficient funds available in
                          the Money Wallet to cover these charges, as per the
                          &#39;Projections for Quarter&#39; data provided in the
                          FEES &amp; TAXES page.
                        </li>
                        <li>
                          Customer Acknowledge that in the event that sufficient
                          funds are not maintained in the User&#39;s Money
                          Wallet at time of payment processing, the cumulative
                          Invoice value of the One-time Onboarding Fee and
                          Client Relationship Fee will be{" "}
                          <b>deducted from any Coupons or Dividends</b>
                          payable to the User on their designated payment dates.
                        </li>
                        <li>
                          The Customer acknowledges that the GST invoices for
                          all fees charged to Users will be uploaded onto the
                          FEES &amp; TAXES page of the Website. Additionally,
                          copies of these Invoices will be sent via email to the
                          User&#39;s registered Email ID. Users with GSTN must
                          upload their GST Certificates on the SCDND Estates web
                          portal to claim Input Tax Credit (ITC) as per
                          applicable regulations.
                        </li>
                        <li>
                          The User acknowledges that upon selection of the
                          &quot;Direct&quot; option during the Signup process on
                          the KYC page, a dedicated Relationship Manager will be
                          appointed for him by the Company, who shall serve as
                          the primary point of contact for all inquiries and
                          customer service matters pertaining to their
                          investment portfolio. The Relationship Manager shall
                          be responsible for providing assistance and support to
                          the User in managing their online operations.
                        </li>
                        <li>
                          User Acknowledge that in the event he/she select the
                          &quot;Assisted&quot; option during the Signup process
                          and provides an <b>Agency Code</b>, the Agent
                          corresponding to the provided Agency Code shall be
                          deemed the Relationship Manager on behalf of the
                          Company. The said Agent shall perform all duties and
                          responsibilities in accordance with the terms and
                          conditions outlined in this policy.
                        </li>
                        <li>
                          The User acknowledges and agrees that the designated
                          Relationship Manager may be contacted through the
                          channels provided by the Company to address queries,
                          concerns, and requests regarding their investment
                          portfolio. The User reserves the right to change the
                          Agency Code themselves, subject to OTP verification,
                          on the Edit Profile page. In the event that an
                          existing Agent ceases to be a partner of SCDND Estates
                          while the User is still utilizing their services, the
                          User’s Signup mode will automatically transition to
                          “Direct”. In such cases, a Relationship Manager will
                          be appointed directly by the Company, with or without
                          prior notice to the User. By proceeding with the
                          Signup process and selecting either the
                          &quot;Direct&quot; or &quot;Assisted&quot; option, the
                          User expressly consents to the appointment of a
                          Relationship Manager and agrees to adhere to the terms
                          and conditions outlined herein.
                        </li>
                        <li>
                          Clients acknowledge and understand that the
                          Agents&#39; role is strictly limited to facilitating
                          transactions and providing information about SCDND
                          Estates&#39; products. Agents of SCDND Estates operate
                          solely as facilitators for the financial products
                          offered by SCDND Estates and do not possess direct or
                          indirect control over the design, structure, or
                          performance of said products. Furthermore, the Agents
                          assume no liability for the performance outcomes of
                          Client portfolios and are not involved in the
                          investment decision-making process of SCDND Estates.
                        </li>
                        <li>
                          User acknowledge that SCDND Estates acts as a
                          market-maker, providing liquidity to financial
                          products held by Clients. However, SCDND Estates does
                          not guarantee the prices offered (as a counterparty)
                          and only provides indications based on the present
                          values/market prices of the corresponding benchmarks.
                          Clients acknowledge and agree that prices offered may
                          fluctuate and are subject to market conditions. SCDND
                          Estates shall not be held liable for any losses
                          incurred on the portfolio due to price discrepancies,
                          market fluctuations or illiquidity.
                        </li>
                        <li>
                          SCDND Estates makes NO GUARANTEE of returns on the
                          User&#39;s portfolio. Furthermore, SCDND Estates DOES
                          NOT promise extraordinary returns or endorse
                          get-rich-quick schemes of any nature whatsoever.
                        </li>
                        <li>
                          User may wish to obtain advice from a qualified
                          financial adviser, pursuant to a separate engagement,
                          before making a commitment to purchase any of the
                          investment products mentioned herein. In the event
                          that you choose not to obtain advice from a qualified
                          financial adviser, you should assess and consider
                          whether the investment product is suitable for you
                          before proceeding to invest and we do not offer any
                          advice in this regard. You are advised to read the
                          relevant Risk Disclosure document carefully before
                          investing in any of the products. The contents of this
                          Website, including these Terms, are subject to change
                          and may be modified, deleted or replaced from time to
                          time at the sole and absolute discretion of SCDND
                          Estates.
                        </li>
                      </ol>
                    </p>
                    <div className="fatca-check mb-0 px-2 py-1">
                      <input
                        type="checkbox"
                        name="check2"
                        className="checkbox-style"
                        id="check2"
                        disabled={status === 1 || !isAtBottom}
                        checked={check}
                        onChange={(e) => setCheck(!check)}
                        required
                      />
                      <label className="text-sm " htmlFor="check2 ">
                        &nbsp;Terms of Asset Management
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center py-3">
              <button
                className={` ${
                  loader3 || status === 1
                    ? "bg-[#717171] cursor-not-allowed"
                    : "cursor-pointer  bg-[#5ab2ff] hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                } flex h-10 w-[120px] items-center justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm `}
                type="submit"
                disabled={loader3 || status === 1}
              >
                {loader3 ? (
                  <img
                    className="userkyc-submit-btn"
                    src="/images/btn-transparent.gif"
                    alt=""
                    height={30}
                  />
                ) : (
                  <>Save</>
                )}
              </button>
            </div>
          </form>
        </>
      )}

      {showModal1 && (
        <AlertBlue
          message="You will be charged one-time onboarding fee between 0.1%-0.5% based on the investment amount."
          onClose={() => setShowModal1(false)}
        />
      )}
      {showModal2 && (
        <AlertBlue
          message="You will be charged one-time onboarding fee between 0.1%-0.5% based on the investment amount."
          onClose={() => setShowModal2(false)}
        />
      )}
      {showModal3 && (
        <AlertRed
          message="Refresh token not provided or connection failure."
          onClose={() => setShowModal3(false)}
        />
      )}
      {message !== "" &&
        (message.includes("already submitted") ? (
          <AlertRed
            message={message}
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        ) : message.includes("successfully") ? (
          <AlertGreen
            message={message}
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        ) : (
          <AlertYellow
            message={message}
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        ))}
      {message2 !== "" &&
        (message2 === "Logged out successfully" ? (
          <AlertGreen
            message={message2}
            onClose={() => {
              setMessage2("");
              handleNavigate2();
            }}
          />
        ) : (
          <AlertRed
            message={message2}
            onClose={() => {
              setMessage2("");
              handleNavigate2();
            }}
          />
        ))}
    </>
  );
}

export default Signup_Mode;
