import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Loader2 from "../components/Loaders/Loader2.jsx";
import CheckAuth from "../components/ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdNotificationsNone } from "react-icons/md";
import AlertRed from "../../src/components/Alerts/AlertRed.jsx";
import AlertGreen from "../../src/components/Alerts/AlertGreen.jsx";
import { FaUser } from "react-icons/fa6";

function Navbar({ handleSidebar }) {
  const { email, phone } = CheckAuth();
  const [sideBar, setSideBar] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const navigate = useNavigate();
  const [openLogout, setOpenLogout] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const navigateTimeout = useRef(null);
  const [message2, setMessage2] = useState("");

  const handleNavigate2 = () => {
    if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    window.location.href = "/userLost";
  };

  useEffect(() => {
    if (message2 !== "") {
      setTimeout(() => {
        setMessage2("");
      }, 3000);
      navigateTimeout.current = setTimeout(handleNavigate2, 3000);
    }
    return () => {
      if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    };
  }, [message2]);

  useEffect(() => {
    if (email === "" || phone === "") {
      setLoader2(true);
    } else if (email === "scdnd.estates@gmail.com" && phone === "9163213191") {
      setLoader2(false);
    } else {
      navigate("/");
    }
  }, [email, phone]);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  // logout function
  const logout = () => {
    setLoader2(true);
    const refreshToken = getCookie("refresh_token");
    secureLocalStorage.clear();
    if (refreshToken) {
      fetch(`https://api-scdndestates.com/api/logout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to logout");
          }
          return response.json();
        })
        .then((response) => {
          document.cookie =
            "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          if (response.error === "Logged out successfully") {
            const currentUrl = new URL(window.location.href);
            if (currentUrl.pathname === "/") {
              window.location.reload();
            } else {
              window.location.href = "/";
            }
          } else {
            setMessage2(response.error);
          }
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    } else {
      setLoader2(false);
      setShowModal1(true);
      setTimeout(() => {
        setShowModal1(false);
      }, 3000);

      window.location.href = "/accessDenied";
    }
  };
  // logout function.

  return (
    <div>
      {loader2 ? (
        <Loader2 />
      ) : (
        <>
          <nav className="max-md:hidden md:hidden lg:flex admin-navbar col-lg-12 col-12 p-0">
            <div
              className="text-center admin-navbar-brand-wrapper d-flex align-items-center justify-content-center"
              style={{ width: sideBar === true ? "300px" : "110px" }}
            >
              <a href="/">
                <img
                  src="/images/logo01.png"
                  alt="logo"
                  className="admin-navbar-brand-logo-mini"
                />
              </a>
            </div>
            <div className="flex justify-between px-4 w-100">
              <div className="align-self-center cursor-pointer">
                <FaBars
                  color="#8f8d8d"
                  size={20}
                  onClick={() => {
                    setSideBar(!sideBar);
                    handleSidebar();
                  }}
                />
              </div>

              <div className="flex justify-between items-center">
                <div className="nav-item text-[28px] text-black flex justify-between items-center">
                  <MdNotificationsNone />
                </div>

                {/* Founder Name */}
                <div
                  className="flex justify-between gap-2 items-center rounded-md px-2 py-0.5 relative cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 focus-visible:ring-2 duration-300 border"
                  tabIndex={0}
                  onClick={() => {
                    setOpenLogout(!openLogout);
                    document.activeElement.blur(); // To remove focus after click
                  }}
                >
                  <p className="flex justify-between items-center">
                    <span className="flex flex-col">
                      <span className="text-sm font-bold text-black">
                        Saptarshi Das
                      </span>
                      <span className="text-gray-700 text-xs">
                        Whole-Time Director
                      </span>
                    </span>
                    <i className="text-black">
                      <MdOutlineKeyboardArrowDown />
                    </i>
                  </p>

                  {openLogout && (
                    <button
                      onClick={logout}
                      className="w-full text-black text-sm font-semibold p-2 absolute bg-[#E0F2FE] left-0 -bottom-10 border rounded-md pop-up-animation duration-200"
                    >
                      Logout
                    </button>
                  )}
                </div>
              </div>
            </div>
          </nav>
          <nav className="admin-navbar col-lg-12 col-12 p-0 max-md:flex md:flex lg:hidden w-screen align-items-center justify-between px-4">
            <a href="/">
              <img
                src="/images/logo01.png"
                alt="logo"
                className="admin-navbar-brand-logo-mini"
              />
            </a>
            <div className="flex justify-end w-100">
              <div className="flex justify-between items-center">
                <div className="pr-2 text-[28px] text-black flex justify-between items-center">
                  <MdNotificationsNone />
                </div>
              </div>

              {/* Founder Name */}
              {/* <div
                className="flex justify-between gap-2 items-center rounded-md px-2 py-0.5 relative cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 focus-visible:ring-2 duration-300 border"
                tabIndex={0}
                onClick={() => {
                  setOpenLogout(!openLogout);
                  document.activeElement.blur(); // To remove focus after click
                }}
              >
                <FaUser />
                {openLogout && (
                  <>
                    <div className="absolute right-0 -bottom-24 border rounded-md pop-up-animation duration-200 w-[150px] bg-[#E0F2FE] p-2">
                      <p className="flex justify-between items-center">
                        <span className="flex flex-col">
                          <span className="text-sm font-bold text-black">
                            Saptarshi Das
                          </span>
                          <span className="text-gray-700 text-xs">
                            Whole-Time Director
                          </span>
                        </span>
                      </p>
                      <button
                        onClick={logout}
                        className="w-full text-black text-sm font-semibold p-2 border rounded-md pop-up-animation duration-200"
                      >
                        Logout
                      </button>
                    </div>
                  </>
                )}
              </div> */}

              {/* Founder Name */}
              <div
                className="flex justify-between gap-2 items-center rounded-md px-2 py-0.5 relative cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 focus-visible:ring-2 duration-300 border"
                tabIndex={0}
                onClick={() => {
                  setOpenLogout(!openLogout);
                  document.activeElement.blur(); // To remove focus after click
                }}
              >
                <p className="flex justify-between items-center">
                  <span className="flex flex-col">
                    <span className="text-sm font-bold text-black">
                      Saptarshi Das
                    </span>
                    <span className="text-gray-700 text-xs">
                      Whole-Time Director
                    </span>
                  </span>
                  <i className="text-black">
                    <MdOutlineKeyboardArrowDown />
                  </i>
                </p>

                {openLogout && (
                  <button
                    onClick={logout}
                    className="w-full text-black text-sm font-semibold p-2 absolute bg-[#E0F2FE] left-0 -bottom-10 border rounded-md pop-up-animation duration-200"
                  >
                    Logout
                  </button>
                )}
              </div>
            </div>
          </nav>
        </>
      )}
      {showModal1 && (
        <AlertRed
          message="Refresh token not provided or connection failure"
          onClose={() => setShowModal1(false)}
        />
      )}
      {message2 !== "" &&
        (message2 === "Logged out successfully" ? (
          <AlertGreen
            message={message2}
            onClose={() => {
              setMessage2("");
              handleNavigate2();
            }}
          />
        ) : (
          <AlertRed
            message={message2}
            onClose={() => {
              setMessage2("");
              handleNavigate2();
            }}
          />
        ))}
    </div>
  );
}

export default Navbar;
