import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import Loader1 from "../Loaders/Loader1";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";
import secureLocalStorage from "react-secure-storage";
import { FaLongArrowAltRight } from "react-icons/fa";
import AlertRed from "../Alerts/AlertRed.jsx";
import AlertBlue from "../Alerts/AlertBlue.jsx";
import AlertYellow from "../Alerts/AlertYellow.jsx";
import AlertGreen from "../Alerts/AlertGreen.jsx";

function Client_Verification() {
  const { isAuthenticated, email, pan, phone } = CheckAuth();
  const { loader, statusD } = UserData({
    isAuthenticated,
    email,
    phone,
    pan,
    formName: "",
  });
  const paraRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const storedData = secureLocalStorage.getItem("kycData");
  const userKycData = JSON.parse(storedData);
  const userName =
    userKycData === null || userKycData["fullName"] === null
      ? ""
      : userKycData["fullName"];
  const [account, setAccount] = useState("");
  const [address, setAddress] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [accountHolder, setAccountHolder] = useState(userName);
  const [bank, setBank] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branch, setBranch] = useState("");
  const [checked1, setChecked1] = useState(false);
  const [status, setStatus] = useState(0);
  const [nameStatus, setNameStatus] = useState("");
  const [message, setMessage] = useState("");
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [showModal6, setShowModal6] = useState(false);
  const [response2, setresponse2] = useState(null);
  const [message2, setMessage2] = useState("");
  const navigateTimeout = useRef(null);

  const handleScroll = () => {
    const element = paraRef.current;
    if (element) {
      const isScrolledToBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight;
      setIsAtBottom(isScrolledToBottom);
    }
  };

  useEffect(() => {
    const element = paraRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => element.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const handleNavigate = () => {
    if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    if (response2["path"] !== undefined) {
      secureLocalStorage.setItem("kycData", JSON.stringify(response2.data));
      if (response2["path"] === "Edit") {
        navigate("/profile");
      } else {
        navigate("/UserKycForm", {
          state: { selectedCard: response2["path"] },
        });
      }
    }
  };

  useEffect(() => {
    if (message2 !== "") {
      setTimeout(() => {
        setMessage2("");
      }, 3000);
      navigateTimeout.current = setTimeout(handleNavigate, 3000);
    }
    return () => {
      if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    };
  }, [message2]);

  const navigate = useNavigate();

  const verifyAccount = () => {
    setLoader2(true);
    fetch(`https://api-scdndestates.com/api/verify-account/`, {
      method: "POST",
      body: JSON.stringify({
        fullName: userName,
        phone: phone,
        email: email,
        account: account,
        accountType: accountType,
        ifsc: ifsc,
        bank: bank,
        branch: branch,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send otp");
        }
        return response.json();
      })
      .then((response) => {
        if (response.reason === "Bank Account details verified successfully.") {
          if (response.nameMatch === 100) {
            setShowModal6(true);
            setShowModal2(false);
            setShowModal2(false);
            setShowModal3(false);
            setShowModal4(false);
            setShowModal5(false);
            setTimeout(() => {
              setShowModal6(false);
            }, 5000);
            setNameStatus(userName);
            setLoader2(false);
          } else {
            setShowModal2(true);
            setShowModal1(false);
            setShowModal3(false);
            setShowModal4(false);
            setShowModal5(false);
            setShowModal6(false);
            setTimeout(() => {
              setShowModal2(false);
            }, 5000);
            setNameStatus("0");
            setLoader2(false);
          }
        } else {
          setShowModal3(true);
          setShowModal2(false);
          setShowModal1(false);
          setShowModal4(false);
          setShowModal5(false);
          setShowModal6(false);
          setTimeout(() => {
            setShowModal3(false);
          }, 5000);
          setLoader2(false);
        }
      })
      .catch((error) => {
        // console.error("Errorgout:", error);
      });
  };

  const fetchIfscDetails = (e) => {
    setIfsc(e.target.value);
    var ifsc = e.target.value;
    if (ifsc.length === 11) {
      fetch(`https://ifsc.razorpay.com/${ifsc}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((ifsc_data) => {
          setAddress(ifsc_data.ADDRESS);
          setBank(ifsc_data.BANK);
          setBranch(ifsc_data.BRANCH);
          setShowModal1(true);
          setShowModal2(false);
          setShowModal3(false);
          setShowModal4(false);
          setShowModal5(false);
          setShowModal6(false);
          setTimeout(() => {
            setShowModal1(false);
          }, 5000);
          setMessage(
            `The IFSC Code ${ifsc} corresponds to a bank located in ${ifsc_data.ADDRESS}`
          );
        })
        .catch((error) => {
          // console.error("Errorgout:", error);
        });
    }
  };

  const handleRegister = (e) => {
    setLoader3(true);
    e.preventDefault();
    if (!isAuthenticated) {
      setShowModal4(true);
      setShowModal1(false);
      setShowModal3(false);
      setShowModal2(false);
      setShowModal5(false);
      setShowModal6(false);
      setTimeout(() => {
        setShowModal4(false);
      }, 5000);
    } else if (nameStatus === "") {
      setShowModal5(true);
      setShowModal1(false);
      setShowModal3(false);
      setShowModal4(false);
      setShowModal2(false);
      setShowModal6(false);
      setTimeout(() => {
        setShowModal5(false);
      }, 5000);
    } else {
      fetch(`https://api-scdndestates.com/api/user-kyc/`, {
        method: "POST",
        body: JSON.stringify({
          form_name: "clientVerification",
          email: email,
          phone: phone,
          account: account,
          accountType: accountType,
          ifsc: ifsc,
          bank: bank,
          branch: branch,
          accountHolder: userName,
          beneficiary: checked1,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to login");
          }
          return response.json();
        })
        .then((response) => {
          setMessage2(response["error"]);
          setresponse2(response);
          setLoader3(false);
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const handleAccountChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setAccount(inputValue);
    }
  };

  const handleEdits = (form_name) => {
    fetch(`https://api-scdndestates.com/api/user-kyc-edit/`, {
      method: "POST",
      body: JSON.stringify({
        form_name: form_name,
        email: email,
        phone: phone,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to login");
        }
        return response.json();
      })
      .then((response) => {
        secureLocalStorage.setItem("kycData", JSON.stringify(response.data));
        if (response["path"] !== undefined) {
          navigate("/UserKycForm", {
            state: { selectedCard: response["path"] },
          });
        }
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setAccountType(userKycData?.["Account_type"]);
    setAccount(userKycData?.account);
    setBank(userKycData?.bank);
    setIfsc(userKycData?.ifsc);
    setBranch(userKycData?.branch);
    setAccountHolder(userName);
    if (userKycData?.["KYC_status"]["D"] === 10) {
      setStatus(1);
      setChecked1(true);
    } else if (userKycData?.["KYC_status"]["D"] === 5) {
      setStatus(2);
    } else {
      setStatus(0);
    }
    if (userKycData?.panType !== "Individual") {
      setAccountTypeList(["Current", "Cash Credit", "Overdraft"]);
    } else {
      if (userKycData?.["Tax_resident"] === "no") {
        setAccountType("Non-Resident Ordinary");
        setAccountTypeList(["Non-Resident Ordinary"]);
      } else {
        setAccountTypeList(["Savings", "Overdraft"]);
      }
    }
  }, [statusD]);

  return (
    <>
      {loader ? (
        <Loader1 />
      ) : (
        <>
          {showModal1 && (
            <AlertBlue
              message={message}
              className=""
              onClose={() => setShowModal1(false)}
            />
          )}
          <form action="" onSubmit={handleRegister} className="font-size1">
            <div className="border rounded py-3">
              <div className="flex flex-wrap justify-between mx-auto px-3 mb-2">
                <p className=" pb-0 text-secondary">
                  <div className="list-unstyled para-style2 font-size1">
                    <p className="mb-1  flex items-start  text-sm text-[#616161] font-semibold">
                      <span>
                        <FaLongArrowAltRight className="mt-1 mr-2" />
                      </span>
                      The Savings A/c (single/joint) should have the name of
                      Sole/First applicant as 1st A/c holder.
                    </p>
                    <p className="mb-1  flex items-start  text-sm text-[#616161] font-semibold">
                      <span>
                        <FaLongArrowAltRight className="mt-1 mr-2" />
                      </span>
                      For joint savings a/c signing instruction should be
                      'Either or Survivor' only. Only NRO Savings A/c is
                      accepted for Non-resident Indian applicants.
                    </p>
                    <p className="mb-1  flex items-start  text-sm text-[#616161] font-semibold">
                      <span>
                        <FaLongArrowAltRight className="mt-1 mr-2" />
                      </span>
                      For Current A/C, authorized signatory details must match
                      User Profile.
                    </p>
                  </div>
                </p>
              </div>
              <div className="flex flex-wrap justify-between mx-auto px-3 mb-2">
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="AccountType"
                  >
                    Account Type
                  </label>
                  <div>
                    <select
                      className="text-sm py-2 px-2 w-full rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] m-0  mt-1 "
                      name="AccountType"
                      id="AccountType"
                      value={accountType}
                      disabled={status === 1}
                      onChange={(e) => setAccountType(e.target.value)}
                    >
                      <option value="">Select from dropdown</option>
                      {accountTypeList.map((accType, index) => (
                        <option key={index} value={accType}>
                          {accType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="ifsc"
                  >
                    IFSC Code<span className="text-danger">*</span>
                  </label>
                  <input
                    className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                    type="text"
                    id="ifsc"
                    name="ifsc"
                    value={ifsc}
                    onChange={fetchIfscDetails}
                    placeholder="xxxxxxxxxxx"
                    required
                    disabled={status === 1}
                  />
                </div>
              </div>
              <div className="flex flex-wrap justify-between mx-auto px-3 mb-2">
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="BankName"
                  >
                    Bank name<span className="text-danger">*</span>
                  </label>
                  <input
                    className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                    type="text"
                    name="BankName"
                    id="BankName"
                    placeholder="State Bank of India"
                    value={bank}
                    required
                    disabled={status === 1}
                  />
                </div>
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="branchName"
                  >
                    Branch<span className="text-danger">*</span>
                  </label>
                  <input
                    className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                    type="text"
                    name="branchName"
                    id="branchName"
                    placeholder="East Vinod nagar, Mayur Vihar Phase-2, Delhi-110091"
                    value={branch}
                    required
                    disabled={status === 1}
                  />
                </div>
              </div>
              <div className="flex flex-wrap justify-between mx-auto px-3 mb-2">
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="account"
                  >
                    Account No.<span className="text-danger">*</span>
                  </label>
                  <div className="input-group m-0 p-0">
                    <input
                      className="w-9/12 bg-[#eeeeee] text-sm px-2  rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center m-0 py-2 tracking-wider"
                      type="text"
                      id="account"
                      name="account"
                      placeholder="xxxxxxxx"
                      value={account}
                      onChange={handleAccountChange}
                      required
                      disabled={
                        status === 1 ||
                        (nameStatus !== "" && nameStatus !== "0")
                      }
                    />

                    {(nameStatus !== "" && nameStatus !== "0") ||
                    statusD === 10 ? (
                      <span className="bg-green-500 w-3/12 text-sm px-2 text-[#fff] rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center justify-center m-0 py-2">
                        Verified
                      </span>
                    ) : loader2 ? (
                      <span className="bg-dark w-3/12 text-sm px-2 text-[#fff] rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center justify-center m-0 py-2">
                        <img
                          className="userkyc-submit-btn"
                          src="/images/1491.gif"
                          alt=""
                          height={30}
                        />
                      </span>
                    ) : (
                      <span
                        className="bg-dark w-3/12 text-sm px-2 text-[#fff] rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center justify-center m-0 py-2"
                        onClick={() => verifyAccount()}
                      >
                        Verify
                      </span>
                    )}
                  </div>
                </div>
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="AccountHolder"
                  >
                    Account holder's name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group m-0 p-0">
                    <input
                      className="w-11/12 bg-[#eeeeee] text-sm px-2  rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center m-0 py-2 tracking-wider"
                      type="text"
                      name="AccountHolder"
                      id="AccountHolder"
                      value={userName}
                      placeholder="Your name"
                      required
                      disabled
                    />
                    {((nameStatus !== "" && nameStatus !== "0") ||
                      statusD === 10) && (
                      <span className="w-1/12 bg-[#fff] text-sm rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center justify-center tracking-wider">
                        <TiTick color="green" size={30} />
                      </span>
                    )}
                    {nameStatus === "0" && (
                      <span className="w-1/12 bg-[#fff] text-sm   rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center justify-center tracking-wider">
                        <ImCross color="red" size={20} />
                      </span>
                    )}
                  </div>
                  {nameStatus === "0" && statusD !== 5 && (
                    <div className="font-size2 text-danger">
                      Your Bank A/C name must match your PAN name. Do you want
                      to change your Registered Name?&nbsp;
                      <span
                        className="cursor-pointer-style text-primary text-decoration-underline"
                        onClick={() => handleEdits("userDetails2")}
                      >
                        Click here
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap justify-between mx-auto px-3 mb-2">
                <div className="w-full mt-2 ">
                  <div
                    className="rounded-xl fatca-box"
                    ref={paraRef}
                    style={{ maxHeight: "150px", overflowY: "auto" }}
                  >
                    <h6 className="px-2 pt-2 font-bold text-sm">
                      Customer Declaration (Applicable for all customers)
                    </h6>
                    <p className=" m-0 px-2 font-medium text-xs leading-4 my-1">
                      I hereby acknowledge that this declaration is being
                      provided by me inter alia in order to enable the Company
                      to assess my suitability/credit-worthiness for the
                      products and services being offered by the SCDND Estates.
                      <ol className="font-medium text-xs leading-4 " type="1">
                        <li>
                          I affirm that I am the lawful or authorized user of
                          the bank account associated with the Money Wallet and
                          possess the requisite legal authority to authorize
                          transactions originating from said linked bank
                          account.
                        </li>
                        <li>
                          I understand that the Company may offer to me
                          financial products and services for which I authorize
                          the Company to initiate transactions, encompassing
                          deposits and withdrawals, transfers of Interest or
                          Dividend payments, which are payable under my
                          PAN/Aadhaar, to my/our designated bank account. These
                          transactions shall be executed in strict adherence to
                          the instructions furnished by us through the
                          https://scdndestates.com platform, for which purpose I
                          have submitted or will submit in future various
                          personal information to the Company which are true and
                          correct to the best of my knowledge.
                        </li>
                        <li>
                          I/We declare that the information given herein by
                          me/us through Money Wallet are true and correct, which
                          SCDND Estates is entitled to verify directly or
                          through any third-party institutions or agents. I/We
                          also agree that, if any such declarations made by
                          me/us are found to be incorrect, SCDND Estates is not
                          bound to pay any interest/dividends on the Account(s)
                          opened by me/us and is entitled to terminate the
                          Client relationship.
                        </li>
                        <li>
                          I/We shall not be entitled to avail the facilities
                          without the use of a Username and Password. I/We are
                          responsible for maintaining the confidentiality of
                          my/our Password, and shall not allow anyone else to
                          use that at any time. I/We are fully responsible for
                          all activities that occur using the password or
                          Account. I/We are hereby formally requested to notify
                          the Company immediately of any unauthorized use or
                          access of my/our Account, or any other breach of
                          security. The Website will not be liable for any loss
                          that I/We may incur as a result of someone else using
                          the account, either with or without my/our knowledge.
                        </li>
                        <li>
                          I understand that the grant of the facilities,
                          products and services requested by me shall be at the
                          absolute discretion of the Company as per its internal
                          policy, product norms and other guidelines, and
                          non-sanction of such facilities by the Company shall
                          not entitle me to claim any compensation/damages in
                          that respect.
                        </li>
                        <li>
                          I hereby further expressly consent to and authorize
                          the Company (whether acting by itself or through any
                          of its service providers, and whether in automated
                          manner or otherwise), to do and undertake any of the
                          following, in relation to my application details,
                          personal data and sensitive information, papers and
                          data relating to Know Your Customer (KYC), credit
                          information, and any other information whether about
                          me or not as may be deemed necessary by Company
                          (collectively, “Information”):
                          <ol className="font-size2 mb-1" type="i">
                            <li>
                              to collect the Information from me and other
                              physical or online sources including credit
                              information companies, information utilities,
                              websites, databases and repositories (whether
                              public or not);
                            </li>
                            <li>
                              To verify the authenticity, correctness, adequacy
                              etc. of the Information, and to act for and on my
                              behalf for accessing, collecting or verifying of
                              such Information, which may be done without any
                              notice to me;
                            </li>
                            <li>
                              To process information including but not limited
                              to storing, structuring, organizing, reproducing,
                              copying, using, profiling etc., as may be deemed
                              fit by the Company;
                            </li>
                            <li>
                              To store the Information for such period as may be
                              required for contract, law or under the Company’s
                              internal process and policy, whichever is longer;
                            </li>
                            <li>
                              To share and disclose the Information to service
                              providers, consultants, credit information
                              companies, data repositories, information
                              utilities, other Companies and financial
                              institutions, affiliates, subsidiaries,
                              regulators, investigating agencies, regulatory,
                              judicial, quasi-judicial and statutory
                              authorities, or to other persons as may be
                              necessary in connection with the contractual or
                              legal requirements.
                            </li>
                          </ol>
                        </li>
                        <li>
                          I understand and permit the use of any of the
                          aforesaid “Information” the Company for the purposes
                          of credit appraisal, fraud detection, Anti-Money
                          Laundering (AML) obligations, also for entering into
                          contract, for direct marketing, for developing credit
                          scoring models and business strategies, for
                          monitoring, for evaluating and improving the quality
                          of services and products, and for other legitimate
                          interests of the Company.
                        </li>
                        <li>
                          I declare that any appraisal form, application forms,
                          undertakings, declarations etc. executed by me in
                          respect to availing of the facilities from the
                          Company, if authenticated by Digital Signature or
                          electronic consent, shall not be disputed by me and
                          shall bound me as if executed physically by me. I
                          confirm and assure that the mobile
                          number/authentication details provided by me are true
                          and pertain to me. I expressly agree to and subject me
                          to the automated processing, automated profiling and
                          to the automated decision making for its products and
                          services by or on behalf of the Company.
                        </li>
                        <li>
                          If so applicable or required, I provide consent to
                          SCDND Estates for making any modification required in
                          my existing Registration from time to time, based on
                          changes/information as declared by me. However, the
                          liability of getting Registration (if applicable) or
                          updating any change in my existing Registration shall
                          be primarily with me, and the Company may at its
                          discretion assist me in this respect without being
                          obligated or having any liability to do so.
                        </li>
                        <li>
                          If at any time I intend to revoke my consent to the
                          sharing of my Information by the Company, the products
                          and services offered to me and/or availed by me,
                          pursuant to the consent provided herein, the same
                          shall not be available to me and/or I shall be
                          required to initiate closure of all my existing
                          accounts including financial assistances.
                        </li>
                        <li>
                          I/We acknowledge that SCDND Estates retains the
                          unilateral prerogative to augment, alter, delete, or
                          terminate the services provided via the site at its
                          discretion, with or without prior notice. The Company
                          further reserves the right to amend, update, or modify
                          these Terms without prior notification. Continued
                          utilization of the Money Wallet on the web portal
                          subsequent to any such alterations indicates the
                          User&#39;s acceptance of the revised Terms.
                        </li>
                      </ol>
                      Should you have any inquiries or concerns regarding these
                      Terms or encounter any issues with the linking mechanism,
                      please direct your communication to
                      helpdesk@scdndestates.com for prompt assistance. <br />
                      <br />
                      Indemnification: I/We agree to indemnify, defend, and hold
                      harmless the Company, its affiliates, officers, Directors,
                      employees, agents, and representatives from and against
                      any and all claims, liabilities, damages, losses, costs,
                      or expenses (including reasonable attorneys&#39; fees)
                      arising out of or related to any transaction authenticated
                      through the mobile OTP verification system, including but
                      not limited to claims alleging unauthorized access or
                      fraudulent use of my mobile number or account. <br />
                      <br />
                      Limitation of Liability: I/We acknowledge that the Company
                      shall not be liable for any loss, damage, or unauthorized
                      transaction resulting from the use of the mobile OTP
                      verification system, including but not limited to errors,
                      delays, interruptions, or inaccuracies in the transmission
                      or receipt of OTPs, from the side of the Telecom or API
                      service provider. <br />
                      <br />
                      By proceeding with the KYC authentication and OTP
                      verification provided by SCDND Estates to your registered
                      mobile no., I affirmatively acknowledge the transactions
                      to and from my bank account as valid and binding. I also
                      expressly declare that I have read, understood, and agreed
                      to be bound by all the terms of this agreement. If I do
                      not agree to these terms, I will refrain from using the
                      web portal of https://scdndestates.com.
                    </p>
                    <div className="fatca-check mb-0 px-2 py-1">
                      <input
                        type="checkbox"
                        name=""
                        disabled={status === 1 || !isAtBottom}
                        className="checkbox-style"
                        id="check2"
                        required
                        checked={checked1}
                        onChange={(e) => setChecked1(!checked1)}
                      />
                      <label htmlFor="BeneficiaryDeclaration">
                        &nbsp;Beneficiary Declaration
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center py-3">
              <button
                className={` ${
                  loader3 || status === 1
                    ? "bg-[#717171] cursor-not-allowed"
                    : "cursor-pointer  bg-[#5ab2ff] hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                } flex h-10 w-[120px] items-center justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm `}
                type="submit"
                disabled={loader3 || status === 1}
              >
                {loader3 ? (
                  <img
                    className="userkyc-submit-btn"
                    src="/images/btn-transparent.gif"
                    alt=""
                    height={30}
                  />
                ) : (
                  <>Save</>
                )}
              </button>
            </div>
          </form>
        </>
      )}
      {showModal6 && (
        <AlertGreen
          message="Your Bank Account is verified"
          onClose={() => setShowModal6(false)}
        />
      )}
      {showModal2 && (
        <AlertYellow
          message="Your Name is not matching with first A/C holder's name"
          onClose={() => setShowModal2(false)}
        />
      )}
      {showModal3 && (
        <AlertRed
          message="Your Bank Account cannot be verified."
          onClose={() => setShowModal3(false)}
        />
      )}
      {showModal4 && (
        <AlertYellow
          message="Please login!"
          onClose={() => setShowModal4(false)}
        />
      )}
      {showModal5 && (
        <AlertYellow
          message="Please verify your account first!"
          onClose={() => setShowModal5(false)}
        />
      )}
    </>
  );
}

export default Client_Verification;
