import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loader1 from "../Loaders/Loader1";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";
import secureLocalStorage from "react-secure-storage";
import AlertRed from "../Alerts/AlertRed.jsx";
import AlertYellow from "../Alerts/AlertYellow.jsx";
import AlertGreen from "../Alerts/AlertGreen.jsx";

function Tax_Details() {
  const { isAuthenticated, email, pan, phone } = CheckAuth();
  const { loader, statusC } = UserData({
    isAuthenticated,
    email,
    phone,
    pan,
    formName: "",
  });

  const storedData = secureLocalStorage.getItem("kycData");
  const userKycData = JSON.parse(storedData);

  const [y, setY] = useState("yes");
  const [m, setM] = useState("");
  const [z, setZ] = useState("");
  const [a, setA] = useState();
  const [loader2, setLoader2] = useState(false);
  const [qualification, setQualification] = useState("");
  const [qualTypeValues, setQualTypeValues] = useState([
    "",
    "High School",
    "Diploma",
    "Graduate",
    "PostGraduate",
    "Doctorate",
  ]);
  const [occupTypeValues, setOccupTypeValues] = useState([
    "",
    "Student",
    "Salaried",
    "Contractual",
    "Self-employed",
    "Professional",
    "Govtment Employee",
    "Business",
    "Homemaker",
    "Others",
  ]);
  const [occupation, setOccupation] = useState("");
  const [fatcaDeclare, setFataDeclare] = useState(false);
  const [exemption, setExemption] = useState(false);
  const [taxNum, setTaxNum] = useState();
  const [showModal1, setShowModal1] = useState(false);
  const [response2, setresponse2] = useState(null);
  const [message, setMessage] = useState("");
  const navigateTimeout = useRef(null);
  const paraRef = useRef(null);
    const [isAtBottom, setIsAtBottom] = useState(true);

  const handleScroll = () => {
    const element = paraRef.current;
    if (element) {
      const isScrolledToBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight;
      setIsAtBottom(isScrolledToBottom);
    }
  };

  useEffect(() => {
    const element = paraRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => element.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const handleNavigate = () => {
    if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    if (response2["path"] !== undefined) {
      secureLocalStorage.setItem("kycData", JSON.stringify(response2.data));
      if (response2["path"] === "Edit") {
        navigate("/profile");
      } else {
        navigate("/UserKycForm", {
          state: { selectedCard: response2["path"] },
        });
      }
    }
  };

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
      navigateTimeout.current = setTimeout(handleNavigate, 3000);
    }
    return () => {
      if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    };
  }, [message]);

  const navigate = useNavigate();

  const handleY = (e) => {
    setY(e.target.value);
  };

  const handleM = (e) => {
    setM(e.target.value);
  };

  const handleZ = (e) => {
    setZ(e.target.value);
  };

  const handleA = (e) => {
    setA(e.target.value);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (isAuthenticated === 0) {
      setShowModal1(true);
      setTimeout(() => {
        setShowModal1(false);
      }, 5000);
    } else {
      setLoader2(true);
      fetch(`https://api-scdndestates.com/api/user-kyc/?`, {
        method: "POST",
        body: JSON.stringify({
          form_name: "taxStatus",
          email: email,
          phone: phone,
          qualification: qualification,
          occupation: occupation,
          incomelevel: z,
          taxResidentIndian: y,
          taxResidentUs: a,
          politicallyExposed: m,
          exemption: exemption,
          fatcaDeclare: fatcaDeclare,
          taxNum: taxNum,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to login");
          }
          return response.json();
        })
        .then((response) => {
          setMessage(response["error"]);
          setresponse2(response);
          setLoader2(false);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const updatedQualTypeValues = userKycData?.qualification
      ? [
          userKycData.qualification,
          ...qualTypeValues
            .slice(1)
            .filter((type) => type !== userKycData?.qualification),
        ]
      : qualTypeValues;
    setQualTypeValues(updatedQualTypeValues);

    const updatedOccupTypeValues = userKycData?.occupation
      ? [
          userKycData.occupation,
          ...occupTypeValues
            .slice(1)
            .filter((type) => type !== userKycData?.occupation),
        ]
      : occupTypeValues;
    setOccupTypeValues(updatedOccupTypeValues);

    setQualification(userKycData?.qualification);
    setOccupation(userKycData?.occupation);
    setY(userKycData?.["Tax_resident"]);
    setA(userKycData?.["tax Resident"]);
    setTaxNum(userKycData?.taxNumber);
    setM(userKycData?.["Politically_exposed"]);
    setZ(userKycData?.incomelevel);
    setExemption(userKycData?.exemption);
    setFataDeclare(userKycData?.["Fatca_declaration"]);
  }, [statusC]);

  return (
    <>
      {loader ? (
        <Loader1 />
      ) : (
        <>
          <form action="" onSubmit={handleRegister} className="font-size1">
            <div className="border rounded-xl p-0 md:p-3 mt-3">
              <div className="flex flex-wrap justify-between mx-auto px-3 py-1 mb-2">
                {/* Qualification */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="qualification"
                  >
                    Qualification<span className="text-danger">*</span>
                  </label>
                  <div>
                    <select
                      className="text-sm py-1.5 w-full px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] m-0  mt-1"
                      name="qualification"
                      id="qualification"
                      value={qualification}
                      disabled={
                        statusC === 20 || statusC === 10 || statusC === 15
                      }
                      onChange={(e) => setQualification(e.target.value)}
                    >
                      {qualTypeValues.map((type, index) => (
                        <option key={index} value={type}>
                          {type === "" ? "Select from Dropdown" : type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Occupation */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="occupation"
                  >
                    Occupation<span className="text-danger">*</span>
                  </label>
                  <div>
                    <select
                      className="text-sm py-1.5 w-full px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] m-0  mt-1"
                      name="OccupationType"
                      id="OccupationType"
                      value={occupation}
                      disabled={
                        statusC === 20 || statusC === 10 || statusC === 15
                      }
                      onChange={(e) => setOccupation(e.target.value)}
                    >
                      {occupTypeValues.map((type, index) => (
                        <option key={index} value={type}>
                          {type === "" ? "Select from Dropdown" : type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {/* Resident of india */}
              <div className="flex flex-wrap justify-between mx-auto px-3 py-1">
                <div className="w-full lg:w-[49%] mt-2">
                  <h6 className="text-sm py-2 px-2 m-0  mt-1">
                    Are you a tax resident of India?
                  </h6>
                  <div className="flex px-2">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="taxResident"
                        id="yes"
                        value="yes"
                        checked={y === "yes"}
                        onChange={handleY}
                        disabled={
                          statusC === 5 || statusC === 10 || statusC === 20
                        }
                      />
                      <label className="form-check-label" htmlFor="yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="taxResident"
                        id="no"
                        value="no"
                        checked={y === "no"}
                        onChange={handleY}
                        disabled={
                          statusC === 5 || statusC === 10 || statusC === 20
                          // status2 === 1
                        }
                      />
                      <label className="form-check-label" htmlFor="no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {y === "no" && (
                  <>
                    <div className="w-full lg:w-[49%] mt-2">
                      <h6 className="text-sm py-2 px-2 m-0  mt-1">
                        Are you a tax resident of?
                      </h6>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="taxResident2"
                            id="usCanada"
                            value="Us/Canada"
                            checked={a === "Us/Canada"}
                            onChange={handleA}
                            disabled={
                              statusC === 5 || statusC === 10 || statusC === 20
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="usCanada"
                          >
                            US/Canada
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="taxResident2"
                            id="other"
                            value="other"
                            checked={a === "other"}
                            onChange={handleA}
                            disabled={
                              statusC === 5 || statusC === 10 || statusC === 20
                            }
                          />
                          <label className="form-check-label" htmlFor="other">
                            Other than US/Canada
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 px-3 d-flex flex-column my-1">
                      <label htmlFor="taxNumber">
                        Social Security/Identificaton Number
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control font-size1 py-2 px-2"
                        type="text"
                        id="taxNumber"
                        name="taxNumber"
                        value={taxNum}
                        onChange={(e) => setTaxNum(e.target.value)}
                        required
                        disabled={
                          statusC === 5 || statusC === 10 || statusC === 20
                        }
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="flex flex-wrap justify-between mx-auto px-3 py-1 mb-2">
                <div className="w-full lg:w-[49%] mt-2">
                  <h6 className="text-sm py-2 px-2 m-0  mt-1">
                    Are you a politically exposed person?
                  </h6>
                  <div className="flex px-2">
                    <div className="form-check form-check-inline ">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="politician"
                        id="yes"
                        value="yes"
                        checked={m === "yes"}
                        onChange={handleM}
                        disabled={
                          statusC === 20 || statusC === 10 || statusC === 15
                        }
                      />
                      <label className="form-check-label" htmlFor="yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="politician"
                        id="no"
                        value="no"
                        checked={m === "no"}
                        onChange={handleM}
                        disabled={
                          statusC === 20 || statusC === 10 || statusC === 15
                        }
                      />
                      <label className="form-check-label" htmlFor="no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="income"
                  >
                    Income Level<span className="text-danger">*</span>
                  </label>
                  <div>
                    <select
                      className="text-sm py-1.5 w-full px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] m-0  mt-1"
                      name="IncomeLevel"
                      id="IncomeLevel"
                      value={z}
                      onChange={handleZ}
                      disabled={
                        statusC === 20 || statusC === 5 || statusC === 15
                      }
                    >
                      <option value="">Select from dropdown</option>
                      <option value="0 to 2.5lacs">0 to 2.5lacs</option>
                      <option value="2.5 lacs to 5 lacs">
                        2.5 lacs to 5 lacs
                      </option>
                      <option value="5 Lacs to 7 Lacs">5 Lacs to 7 Lacs</option>
                      <option value="7 Lacs to 12 Lacs">
                        7 Lacs to 12 Lacs
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-between mx-auto px-3 py-1 mb-2">
                {(z === "0 to 2.5lacs" || z === "2.5 lacs to 5 lacs") && (
                  <div className="w-full lg:w-[49%] mt-2 flex flex-row align-items-start text-sm">
                    <input
                      type="checkbox"
                      className="ml-2 mt-1 "
                      name=""
                      id="check1"
                      checked={exemption}
                      disabled={
                        statusC === 20 || statusC === 5 || statusC === 15
                      }
                      onClick={(e) => setExemption(!exemption)}
                    />
                    <label className="pl-1 text-sm" htmlFor="check1 ">
                      &nbsp;Are you eligible for exemption from 10% TDS
                      deduction by filing 80G/H?
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                )}
              </div>
              <div className="flex flex-wrap justify-between mx-auto px-3 py-1 mb-2">
                {(a !== "other" && y !== "yes") || (
                  <>
                    <div className="w-full mt-2 ">
                      <div
                        className="rounded-xl fatca-box "
                        ref={paraRef}
                        style={{ maxHeight: "150px", overflowY: "auto" }}
                      >
                        <h6 className="  px-2 pt-2 font-bold text-sm">
                          Customer Declaration (Applicable for all customers)
                        </h6>
                        <p className="m-0 px-2">
                          <ol
                            className="font-medium text-xs leading-4 my-1"
                            type="I"
                          >
                            <li>
                              The applicant is an applicant taxable as a tax
                              resident under the laws of country outside India.
                              (This clause is applicable only if the account
                              holder Is a tax resident outside of India).
                            </li>
                            <li>
                              I/We understand that the SCDND Estates is relying
                              on this information for the purpose of determining
                              the status of the applicant named above in
                              compliance with FATCA/CRS. The SCDND Estates is
                              not able to offer any tax advice on CRS or FATCA
                              or its impact on the applicant. I/we shall seek
                              advice from professional tax advisor for any tax
                              questions.
                            </li>
                            <li>
                              I/We agree to submit a new form within 30 days if
                              any information or certification on this form
                              becomes incorrect.
                            </li>
                            <li>
                              I/We agree that as may be required by domestic
                              regulators/tax authorities the SCDND Estates may
                              also be required to report, reportable details to
                              CBDT or close or suspend my account.
                            </li>
                            <li>
                              I/We certify that I/we provide the information on
                              this form and to the best of my/our knowledge and
                              belief the certification is true, correct, and
                              complete including the taxpayer identification
                              number of the applicant.
                            </li>
                            <li>
                              I/We permit/authorise SCDND Estates to collect,
                              store, communicate and process information
                              relating to the Account and all transactions
                              therein, by SCDND Estates and any of its
                              affiliates wherever situated including sharing,
                              transfer and disclosure between them and to the
                              authorities in and/or outside India of any
                              confidential information for compliance with any
                              law or regulation whether domestic or foreign.
                            </li>
                            <li>
                              I / We hereby accept and acknowledge that SCDND
                              Estates shall have the right and authority to
                              carry out investigations from the information
                              available in public domain for confirming the
                              information provided by me / us to SCDND Estates.
                            </li>
                            <li>
                              I/We shall indemnify SCDND Estates for any loss
                              that may arise to SCDND Estates on account of
                              providing incorrect or incomplete information.
                            </li>
                          </ol>
                        </p>
                        <div className="fatca-check mb-0 px-2 py-1 ">
                          <input
                            type="checkbox"
                            name=""
                            className="checkbox-style"
                            checked={fatcaDeclare}
                            onClick={(e) => setFataDeclare(!fatcaDeclare)}
                            id="check2"
                            required
                            disabled={
                              statusC === 5 ||
                              statusC === 10 ||
                              statusC === 20 ||
                              !isAtBottom
                            }
                          />
                          <label htmlFor="check2">
                            &nbsp;FATCA Declaration
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex justify-center py-3 ">
              <button
                className={` ${
                  loader2 || statusC === 20
                    ? "bg-[#717171] cursor-not-allowed"
                    : "cursor-pointer  bg-[#5ab2ff] hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                } flex h-10 w-[120px] items-center justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm    `}
                type="submit"
                disabled={loader2 || statusC === 20}
              >
                {loader2 ? (
                  <img
                    className="userkyc-submit-btn"
                    src="/images/btn-transparent.gif"
                    alt=""
                    height={30}
                  />
                ) : (
                  <>Save</>
                )}
              </button>
            </div>
          </form>
        </>
      )}
      {showModal1 && (
        <AlertYellow
          message="Please login again!"
          onClose={() => setShowModal1(false)}
        />
      )}
      {message !== "" &&
        (message.includes("already submitted") ? (
          <AlertRed
            message={message}
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        ) : message.includes("successfully") ? (
          <AlertGreen
            message={message}
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        ) : (
          <AlertYellow
            message={message}
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        ))}
    </>
  );
}
export default Tax_Details;
