import { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import { FaLocationArrow } from "react-icons/fa6";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";
import UserData from "../../components/ServiceFunctions/UserData.jsx";
import Loader2 from "../../components/Loaders/Loader2.jsx";
import KycPopUp from "../../components/PopUp1/PopUp2.jsx";
import PopUp1 from "../../components/PopUp1/PopUp1.jsx";
import secureLocalStorage from "react-secure-storage";
import "../CapitalGain/CapitalGain.css";
import AlertRed from "../../components/Alerts/AlertRed.jsx";

function CapitalGain() {
  const { isAuthenticated, email, phone, pan, kycStatus } = CheckAuth();
  const { loader } = UserData({
    isAuthenticated,
    email,
    phone,
    formName: "",
  });
  const [dropdownState, setDropdownState] = useState({
    openDropdown: null,
    selectedYear: "Financial Year",
    selectedQuarter: "Quarter",
  });
  const [view, setView] = useState("");
  const [term, setTerm] = useState("");
  const storedData = secureLocalStorage.getItem("blockchainData");
  const blockchainData = JSON.parse(storedData);
  const storedData2 = secureLocalStorage.getItem("kycData");
  const kycData = JSON.parse(storedData2);
  const storedData3 = secureLocalStorage.getItem("couponData");
  const couponData = JSON.parse(storedData3);
  const relationshipDate = kycData?.["Relationship_start"];
  const [array, setArray] = useState({});
  const [array2, setArray2] = useState({});
  const [walrLTSell, setWalrLTSell] = useState(0);
  const [walrSTSell, setWalrSTSell] = useState(0);
  const [residexLTSell, setResidexLTSell] = useState(0);
  const [residexSTSell, setResidexSTSell] = useState(0);
  const [animate, setAnimate] = useState(-1);
  const [animate2, setAnimate2] = useState(-1);
  const [animate3, setAnimate3] = useState(-1);
  const [animate4, setAnimate4] = useState(-1);
  const [showModal, setShowModal] = useState(false);

  const toggleDropdown = (dropdown) => {
    setDropdownState((prevState) => ({
      ...prevState,
      openDropdown: prevState.openDropdown === dropdown ? null : dropdown,
    }));
  };

  function parseDate(dateStr) {
    return new Date(dateStr.replace(/(\d{2}) (\w{3}) (\d{2})/, "$2 $1, $3"));
  }

  const fiscalYearMapping = {
    "Q1 (Apr-Jun)": { start: 4, end: 6 },
    "Q2 (Jul-Sep)": { start: 7, end: 9 },
    "Q3 (Oct-Dec)": { start: 10, end: 12 },
    "Q4 (Jan-Mar)": { start: 1, end: 3 },
  };

  const selectedQuarterRange = fiscalYearMapping[dropdownState.selectedQuarter];

  const getFiscalYearStartEnd = (selectedYear) => {
    const [startYear, endYear] = selectedYear
      ?.split("FY ")[1]
      ?.split("-")
      ?.map(Number);
    return { startYear, endYear: 2000 + endYear }; // Assumes 'endYear' is a two-digit year
  };

  const handleCheck = () => {
    if (
      dropdownState.selectedYear === "Financial Year" ||
      dropdownState.selectedQuarter === "Quarter"
    ) {
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 5000);
    } else {
      const { startYear, endYear } = getFiscalYearStartEnd(
        dropdownState.selectedYear
      );

      const updatedArray2 = {};
      const finalArray = {
        WALR: { LT: {}, ST: {} },
        RESIDEX: { LT: {}, ST: {} },
      };

      const filteredData = blockchainData?.filter((entry) => {
        const timestamp = new Date(entry?.Timestamp);
        const year = timestamp.getFullYear();
        const month = timestamp.getMonth() + 1;

        const isWithinFiscalYear =
          (year === startYear && month >= 4) ||
          (year === endYear && month <= 3);

        const isWithinSelectedQuarter =
          dropdownState.selectedQuarter === "Q4 (Jan-Mar)"
            ? year === endYear &&
              month >= selectedQuarterRange?.start &&
              month <= selectedQuarterRange?.end
            : month >= selectedQuarterRange?.start &&
              month <= selectedQuarterRange?.end;

        return isWithinFiscalYear && isWithinSelectedQuarter;
      });
      const seriesSet = new Set();
      let seriesBuyUnits = 0;
      let seriesBuyPrice = 0;
      let seriesSellUnits = 0;
      let seriesSellPrice = 0;
      filteredData.forEach((data) => {
        if (data.Security.split(" ").slice(2).join(" ")) {
          const securityKey = data.Security.split(" ").slice(2).join(" ");

          // Initialize seriesUnits and seriesPrice if this securityKey has not been encountered
          if (!updatedArray2[securityKey]) {
            seriesBuyUnits = 0;
            seriesBuyPrice = 0;
            seriesSellUnits = 0;
            seriesSellPrice = 0;
          }

          // Accumulate the units and price
          if (data.Buyer === pan) {
            seriesBuyUnits += data.Units;
            seriesBuyPrice += data.Units * data.Price_per_unit;
          }
          if (data.Seller === pan) {
            seriesSellUnits += data.Units;
            seriesSellPrice += data.Units * data.Price_per_unit;
          }
          // Update the object with accumulated values
          seriesSet.add(securityKey);
          updatedArray2[securityKey] = {
            unit: seriesSellUnits - seriesBuyUnits,
            price:
              seriesSellPrice - seriesBuyPrice > 0
                ? seriesSellPrice - seriesBuyPrice
                : 0,
          };
        }
      });

      const startDate = new Date(relationshipDate);
      const updatedArray = {};
      let targetSeries = null;
      let targetUnits = null;
      if (couponData) {
        const dateKeys = Object.keys(couponData)
          .filter((key) => key !== "pan")
          .map((key) => {
            const date = new Date(key);
            return date.toLocaleDateString("en-IN", {
              day: "2-digit",
              month: "short",
              year: "2-digit",
            });
          })
          .sort((a, b) => new Date(a) - new Date(b));
        seriesSet.forEach((security) => {
          let acquisition = null;
          targetSeries = security;
          targetUnits = updatedArray2[security]?.unit;
          let fixedDate;
          dateKeys.forEach((formattedDate) => {
            const endDate = parseDate(formattedDate);
            const year = endDate.getFullYear();
            const month = endDate.getMonth() + 1;

            const isWithinFiscalYear =
              (year === startYear && month >= 4) ||
              (year === endYear && month <= 3);

            const isWithinSelectedQuarter =
              dropdownState.selectedQuarter === "Q4 (Jan-Mar)"
                ? year === endYear &&
                  month >= selectedQuarterRange?.start &&
                  month <= selectedQuarterRange?.end
                : month >= selectedQuarterRange?.start &&
                  month <= selectedQuarterRange?.end;
            if (isWithinFiscalYear && isWithinSelectedQuarter) {
              fixedDate = endDate;
            }
            if (
              endDate >= startDate &&
              endDate <= (fixedDate !== undefined ? fixedDate : endDate)
            ) {
              let total = 0;
              let totalBuy = 0;
              let totalUnits = 0;
              let totalBuyPPU = 0;
              blockchainData.forEach((item) => {
                const itemDate = new Date(item.Timestamp);
                const isInDateRange =
                  itemDate >= startDate && itemDate <= endDate;
                if (
                  isInDateRange &&
                  item["Security"].split(" ").slice(2).join(" ") === security
                ) {
                  if (item.Buyer === pan) {
                    total += item["Units"];
                    totalUnits += item["Units"];
                    totalBuy += item["Units"] * item["Price_per_unit"];
                    totalBuyPPU += item["Price_per_unit"];
                  }
                  if (item.Seller === pan) total -= item["Units"];
                }
              });
              if (!updatedArray[security]) updatedArray[security] = {};
              updatedArray[security][formattedDate] = total;
              acquisition = totalBuy / totalUnits;
            }
          });

          let stepsBack = 0;
          const sortedEntries = Object.entries(updatedArray[security]).sort(
            (b, a) => new Date(a) - new Date(b)
          );

          sortedEntries.forEach(([date, data], i) => {
            if (i > 0) {
              if (targetUnits > data) {
                return stepsBack;
              } else {
                stepsBack++;
              }
            }
          });

          let category = !isNaN(security[5]) ? "WALR" : "RESIDEX";
          let term = stepsBack >= 8 ? "LT" : "ST";

          // Create the object with common properties
          let dataObject = {
            days: updatedArray2[security]?.unit <= 0 ? "NA" : stepsBack * 3,
            sold: updatedArray2[security]?.unit,
            acqu: acquisition,
            soldPrice: updatedArray2[security]?.price,
            buyPrice:
              updatedArray2[security]?.unit <= 0
                ? 0
                : acquisition * updatedArray2[security]?.unit,
          };
          finalArray[category][term][security] = dataObject;
        });
      }
      const calculateSumTotal = (array, key) =>
        array.length === 0
          ? 0
          : array.reduce((sum, entry) => sum + entry[key], 0);

      const finalArray2 = {
        WALR: {
          LT: {
            sell: calculateSumTotal(
              Object.values(finalArray.WALR.LT),
              "soldPrice"
            ),
            buy: calculateSumTotal(
              Object.values(finalArray.WALR.LT),
              "buyPrice"
            ),
          },
          ST: {
            sell: calculateSumTotal(
              Object.values(finalArray.WALR.ST),
              "soldPrice"
            ),
            buy: calculateSumTotal(
              Object.values(finalArray.WALR.ST),
              "buyPrice"
            ),
          },
        },
        RESIDEX: {
          LT: {
            sell: calculateSumTotal(
              Object.values(finalArray.RESIDEX.LT),
              "soldPrice"
            ),
            buy: calculateSumTotal(
              Object.values(finalArray.RESIDEX.LT),
              "buyPrice"
            ),
          },
          ST: {
            sell: calculateSumTotal(
              Object.values(finalArray.RESIDEX.ST),
              "soldPrice"
            ),
            buy: calculateSumTotal(
              Object.values(finalArray.RESIDEX.ST),
              "buyPrice"
            ),
          },
        },
      };

      // animation on rows
      {
        if (
          calculateSumTotal(Object.values(finalArray.WALR.LT), "soldPrice") !==
            walrLTSell &&
          calculateSumTotal(Object.values(finalArray.WALR.LT), "soldPrice") !==
            0
        ) {
          setAnimate(0);
          setTimeout(() => setAnimate(-1), 500); // Animation duration, adjust as needed
        }
        setWalrLTSell(
          calculateSumTotal(Object.values(finalArray.WALR.LT), "soldPrice")
        );

        if (
          calculateSumTotal(Object.values(finalArray.WALR.ST), "soldPrice") !==
            walrSTSell &&
          calculateSumTotal(Object.values(finalArray.WALR.ST), "soldPrice") !==
            0
        ) {
          setAnimate2(1);
          setTimeout(() => setAnimate2(-1), 500); // Animation duration, adjust as needed
        }
        setWalrSTSell(
          calculateSumTotal(Object.values(finalArray.WALR.ST), "soldPrice")
        );

        if (
          calculateSumTotal(
            Object.values(finalArray.RESIDEX.LT),
            "soldPrice"
          ) !== residexLTSell &&
          calculateSumTotal(
            Object.values(finalArray.RESIDEX.LT),
            "soldPrice"
          ) !== 0
        ) {
          setAnimate3(2);
          setTimeout(() => setAnimate3(-1), 500); // Animation duration, adjust as needed
        }
        setResidexLTSell(
          calculateSumTotal(Object.values(finalArray.RESIDEX.LT), "soldPrice")
        );

        if (
          calculateSumTotal(
            Object.values(finalArray.RESIDEX.ST),
            "soldPrice"
          ) !== residexSTSell &&
          calculateSumTotal(
            Object.values(finalArray.RESIDEX.ST),
            "soldPrice"
          ) !== 0
        ) {
          setAnimate4(3);
          setTimeout(() => setAnimate4(-1), 500); // Animation duration, adjust as needed
        }
        setResidexSTSell(
          calculateSumTotal(Object.values(finalArray.RESIDEX.ST), "soldPrice")
        );
      }
      setArray(finalArray);
      setArray2(finalArray2);
    }
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown-button")) {
      setDropdownState((prevState) => ({
        ...prevState,
        openDropdown: "",
      }));
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  // open Breakups
  const toggleView = (viewName, term) => {
    if (view === viewName) {
      setView(""); // Close the view if it's already open
    } else {
      setView(viewName); // Open the selected view
      setTerm(term);
    }
  };

  // Helper function to calculate FY based on a date
  const getFinancialYear = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12
    if (month < 4) {
      // If the month is Jan, Feb, or March, it belongs to the previous FY
      return `FY ${year - 1}-${year.toString().slice(-2)}`;
    } else {
      // Otherwise, it's in the current FY
      return `FY ${year}-${(year + 1).toString().slice(-2)}`;
    }
  };

  // Extract unique financial years from blockchainData
  const financialYears = [
    ...new Set(
      blockchainData?.map((entry) => {
        const date = new Date(entry.Timestamp);
        return getFinancialYear(date);
      })
    ),
  ].sort((a, b) => b.localeCompare(a)); // Sort in descending order

  // Function to animate numbers
  return (
    <>
      <Header />
      <div className="after-header">
        {loader ? (
          <Loader2 />
        ) : !isAuthenticated ? (
          <PopUp1 />
        ) : kycStatus < 85 ? (
          <KycPopUp />
        ) : (
          <>
            <div>
              <div className="max-w-[1800px] mx-auto">
                <div className="w-[90%] mx-auto text-black rounded-md">
                  <div className="p-2">
                    <h1 className="text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-4xl text-center bg-white px-3 w-fit mx-auto">
                      Capital <span className="text-[#0EA5E9]">Gain</span>
                    </h1>
                    <div className="flex justify-center items-center w-full">
                      <p className="text-sm max-w-lg text-center mb-4 mt-2">
                        Capital gain on your decentralized assets held with us,
                        and the Income tax liable on it based on latest
                        amendments of Income Tax Act, 1961.
                      </p>
                    </div>

                    <div className="flex flex-wrap sm:justify-between md:justify-center max-md:gap-3 md:gap-5 items-end rounded-md  py-3 border-y-2">
                      {/* Choose Financial Year */}
                      <div className="relative md:flex items-center gap-2 text-left w-full md:w-auto">
                        <p className="text-sm font-semibold w-full">
                          Financial Year <span className="text-red-600">*</span>
                        </p>
                        <button
                          type="button"
                          className={`dropdown-button flex items-center justify-between gap-2 px-2 py-1 bg-white rounded-md transition-all duration-300 focus:outline-none mt-1 w-full md:min-w-[160px] ${
                            dropdownState.openDropdown === "year"
                              ? "shadow-[0px_0px_0px_1.2px_#0EA5E9] "
                              : "shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                          }`}
                          onClick={() => toggleDropdown("year")}
                        >
                          <span className="text-sm font-semibold">
                            {dropdownState.selectedYear}
                          </span>
                          <svg
                            className={`w-5 h-5 text-gray-400 ${
                              dropdownState.openDropdown === "year"
                                ? "rotate-180 duration-500"
                                : ""
                            }`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" />
                          </svg>
                        </button>

                        {dropdownState.openDropdown === "year" && (
                          <div className="z-20 absolute md:top-9 w-[70%] md:w-44 right-0 bg-white rounded-md shadow-lg  border-b-[2px] border-[#0EA5E9] animate-fadeIn  ">
                            {financialYears?.map((option) => (
                              <a
                                href="#"
                                key={option}
                                className="block px-4 py-2 text-sm font-semibold hover:bg-[#DBEDF9] hover:text-[#0EA5E9] border-b"
                                onClick={() =>
                                  setDropdownState((prevState) => ({
                                    ...prevState,
                                    selectedYear: option,
                                    openDropdown: null,
                                  }))
                                }
                              >
                                {option}
                              </a>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* Choose Quarter */}
                      <div className="relative md:flex items-center gap-2 text-left ">
                        <p className="text-sm font-semibold">
                          Quarter <span className="text-red-600">*</span>
                        </p>
                        <button
                          type="button"
                          className={`dropdown-button flex items-center justify-between gap-2 px-2 py-1 bg-white rounded-md transition-all duration-300 focus:outline-none mt-1 min-w-[160px] ${
                            dropdownState.openDropdown === "quarter"
                              ? "shadow-[0px_0px_0px_1.2px_#0EA5E9]"
                              : "shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                          }`}
                          onClick={() => toggleDropdown("quarter")}
                        >
                          <span className="text-sm font-semibold">
                            {dropdownState.selectedQuarter}
                          </span>
                          <svg
                            className={`w-5 h-5 text-gray-400 ${
                              dropdownState.openDropdown === "quarter"
                                ? "rotate-180 duration-500"
                                : ""
                            }`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" />
                          </svg>
                        </button>

                        {dropdownState.openDropdown === "quarter" && (
                          <div className="absolute md:top-9 w-44 md:right-0 bg-white rounded-md shadow-lg z-20 border-b-[2px] border-[#0EA5E9] animate-fadeIn  ">
                            {[
                              "Q1 (Apr-Jun)",
                              "Q2 (Jul-Sep)",
                              "Q3 (Oct-Dec)",
                              "Q4 (Jan-Mar)",
                            ].map((option) => (
                              <a
                                href="#"
                                key={option}
                                className="block px-4 py-2 text-sm font-semibold hover:bg-[#DBEDF9] hover:text-[#0EA5E9] border-b"
                                onClick={() =>
                                  setDropdownState((prevState) => ({
                                    ...prevState,
                                    selectedQuarter: option,
                                    openDropdown: null,
                                  }))
                                }
                              >
                                {option}
                              </a>
                            ))}
                          </div>
                        )}
                      </div>

                      <button
                        className="px-3 py-1 text-sm rounded font-semibold bg-[#0EA5E9] text-white relative overflow-hidden group z-10 hover:text-white duration-1000 flex items-center gap-1"
                        onClick={() => {
                          handleCheck();
                        }}
                      >
                        <span className="absolute bg-[#347ab8] w-20 h-20 rounded-full group-hover:scale-100 scale-0 -z-10 -left-2 -top-10 group-hover:duration-500 duration-700 origin-center transform transition-all"></span>
                        <span className="absolute bg-[#3B71CA] w-20 h-20 -left-2 -top-10 rounded-full group-hover:scale-100 scale-0 -z-10 group-hover:duration-700 duration-500 origin-center transform transition-all"></span>
                        Check
                        <span>
                          <FaLocationArrow />
                        </span>
                      </button>
                    </div>

                    {/* WALR Table */}
                    <div className="py-4 overflow-x-auto">
                      <h1 className=" px-2.5 py-1.5 w-[120px] md:w-[140px] flex justify-center gap-1 border-2 rounded-full border-[#0590F8] font-bold text-[#0590F8] leading-none tracking-tight text-sm md:text-[15px] sticky left-0">
                        <span className="">WALR </span> Bonds
                      </h1>
                      <div className="inline-block min-w-full shadow-md rounded-lg border-x border-b overflow-hidden mt-1">
                        <table className="min-w-full leading-normal ">
                          <thead>
                            <tr className="text-white font-bold text-xs md:text-sm bg-[#0590F8]">
                              <th className="px-2 py-2.5 border-b-2 border-gray-200  font-semibold tracking-wider">
                                Description
                              </th>
                              <th className="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                                Selling Price
                              </th>
                              <th className="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                                Deduction u/s 48
                              </th>
                              <th className="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                                Tax Rate
                              </th>
                              <th className="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                                Gain/Loss
                              </th>
                              <th className="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                                Breakups
                              </th>
                            </tr>
                          </thead>
                          <tbody className="font-semibold text-xs md:text-sm">
                            <tr>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm min-w-[80px]">
                                <p className="text-gray-900 whitespace-no-wrap ">
                                  Short Term
                                </p>
                              </td>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                                <p
                                  className={`${
                                    animate2 === 1 ? "slide-animation" : ""
                                  } text-gray-900 whitespace-no-wrap text-center flex items-center justify-center`}
                                >
                                  <FaIndianRupeeSign className="text-black text-xs" />{" "}
                                  {walrSTSell.toLocaleString("en-IN") || 0}
                                </p>
                              </td>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                                <p
                                  className={`${
                                    animate2 === 1 ? "slide-animation" : ""
                                  } text-gray-900 whitespace-no-wrap text-center flex items-center justify-center`}
                                >
                                  <FaIndianRupeeSign className="text-black text-xs" />{" "}
                                  0
                                </p>
                              </td>

                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                                <p
                                  className={`${
                                    animate2 === 1 ? "slide-animation" : ""
                                  } text-gray-900 whitespace-no-wrap text-center`}
                                >
                                  12.5%
                                </p>
                              </td>
                              <td className="px-2 py-3 border-b border-gray-200 bg-white text-[13px] md:text-sm flex items-center justify-center gap-2">
                                <span
                                  className={`relative text-xs ${
                                    (array2?.WALR?.ST?.sell || 0) -
                                      (array2?.WALR?.ST?.buy || 0) >=
                                    0
                                      ? "text-green-900"
                                      : "text-red-900"
                                  } ${
                                    animate2 === 1 ? "slide-animation" : ""
                                  } relative px-3 py-1 font-semibold text-green-900 leading-tight flex items-center justify-center`}
                                >
                                  <span
                                    aria-hidden
                                    className={`${
                                      (array2?.WALR?.ST?.sell || 0) -
                                        (array2?.WALR?.ST?.buy || 0) >=
                                      0
                                        ? "text-green-900 bg-green-200"
                                        : "text-red-900 bg-red-200"
                                    } absolute inset-0 opacity-50 rounded-full`}
                                  ></span>
                                  <FaIndianRupeeSign className="relative text-xs" />
                                  <span className="relative">
                                    {array2?.WALR?.ST?.sell > 0
                                      ? (
                                          (array2?.WALR?.ST?.sell || 0) -
                                          (array2?.WALR?.ST?.buy || 0)
                                        ).toLocaleString("en-IN")
                                      : 0}
                                  </span>
                                </span>
                              </td>
                              <td
                                onClick={() => toggleView("walr", "ST")}
                                className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm cursor-pointer"
                              >
                                <span className="flex items-center justify-center">
                                  <img
                                    src="/images/details.svg"
                                    alt=""
                                    className="w-8 h-8 hover:scale-95 duration-300 animate-pulse"
                                  />
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm min-w-[80px]">
                                <p className="text-gray-900 whitespace-no-wrap ">
                                  Long Term
                                </p>
                              </td>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                                <p
                                  className={`${
                                    animate === 0 ? "slide-animation" : ""
                                  } text-gray-900 whitespace-no-wrap text-center flex items-center justify-center`}
                                >
                                  <FaIndianRupeeSign className="text-black text-xs" />{" "}
                                  {walrLTSell.toLocaleString("en-IN") || 0}
                                </p>
                              </td>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                                <p
                                  className={`${
                                    animate === 0 ? "slide-animation" : ""
                                  } text-gray-900 whitespace-no-wrap text-center flex items-center justify-center`}
                                >
                                  <FaIndianRupeeSign className="text-black text-xs" />{" "}
                                  0
                                </p>
                              </td>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                                <p
                                  className={`${
                                    animate === 0 ? "slide-animation" : ""
                                  } text-gray-900 whitespace-no-wrap text-center`}
                                >
                                  12.5%
                                </p>
                              </td>
                              <td className="px-2 py-3 border-b border-gray-200 bg-white text-[13px] md:text-sm flex items-center justify-center gap-2">
                                <span
                                  className={`relative text-xs ${
                                    (array2?.WALR?.LT?.sell || 0) -
                                      (array2?.WALR?.LT?.buy || 0) >=
                                    0
                                      ? "text-green-900"
                                      : "text-red-900"
                                  } ${
                                    animate === 0 ? "slide-animation" : ""
                                  } relative px-3 py-1 font-semibold text-green-900 leading-tight flex items-center justify-center`}
                                >
                                  <span
                                    aria-hidden
                                    className={`${
                                      (array2?.WALR?.LT?.sell || 0) -
                                        (array2?.WALR?.LT?.buy || 0) >=
                                      0
                                        ? "text-green-900 bg-green-200"
                                        : "text-red-900 bg-red-200"
                                    } absolute inset-0 opacity-50 rounded-full`}
                                  ></span>
                                  <FaIndianRupeeSign className="relative text-xs" />
                                  <span className="relative">
                                    {array2?.WALR?.LT?.sell > 0
                                      ? (
                                          (array2?.WALR?.LT?.sell || 0) -
                                          (array2?.WALR?.LT?.buy || 0)
                                        ).toLocaleString("en-IN")
                                      : 0}
                                  </span>
                                </span>
                              </td>
                              <td
                                onClick={() => toggleView("walr", "LT")}
                                className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm cursor-pointer"
                              >
                                <span className="flex items-center justify-center">
                                  <img
                                    src="/images/details.svg"
                                    alt=""
                                    className="w-8 h-8 hover:scale-95 duration-300 animate-pulse"
                                  />
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {/* Residex Table */}
                    <div className="py-4 overflow-x-auto">
                      <h1 className=" px-2.5 py-1.5 w-[120px] md:w-[140px] flex justify-center gap-1 border-2 rounded-full border-[#009688] font-bold text-[#009688] leading-none tracking-tight text-sm md:text-[15px] sticky left-0">
                        <span className="">RESIDEX </span> Bonds
                      </h1>
                      <div className="inline-block min-w-full shadow-md rounded-lg border-x border-b overflow-hidden mt-1">
                        <table className="min-w-full leading-normal ">
                          <thead>
                            <tr className="text-white font-bold text-xs md:text-sm bg-[#009688]">
                              <th className="px-2 py-2.5 border-b-2 border-gray-200  font-semibold tracking-wider">
                                Description
                              </th>
                              <th className="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                                Selling Price
                              </th>
                              <th className="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                                Deduction u/s 48
                              </th>
                              <th className="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                                Tax Rate
                              </th>
                              <th className="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                                Gain/Loss
                              </th>
                              <th className="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                                Breakups
                              </th>
                            </tr>
                          </thead>
                          <tbody className="font-semibold text-xs md:text-sm">
                            <tr>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm min-w-[80px]">
                                <p className="text-gray-900 whitespace-no-wrap ">
                                  Short Term
                                </p>
                              </td>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                                <p
                                  className={`${
                                    animate4 === 3 ? "slide-animation" : ""
                                  } text-gray-900 whitespace-no-wrap text-center flex items-center justify-center`}
                                >
                                  <FaIndianRupeeSign className="text-black text-xs" />{" "}
                                  {residexSTSell.toLocaleString("en-IN") || 0}
                                </p>
                              </td>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                                <p
                                  className={`${
                                    animate4 === 3 ? "slide-animation" : ""
                                  } text-gray-900 whitespace-no-wrap text-center flex items-center justify-center`}
                                >
                                  <FaIndianRupeeSign className="text-black text-xs" />{" "}
                                  0
                                </p>
                              </td>

                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                                <p
                                  className={`${
                                    animate4 === 3 ? "slide-animation" : ""
                                  } text-gray-900 whitespace-no-wrap text-center`}
                                >
                                  12.5%
                                </p>
                              </td>
                              <td className="px-2 py-3 border-b border-gray-200 bg-white text-[13px] md:text-sm flex items-center justify-center gap-2">
                                <span
                                  className={`relative text-xs ${
                                    (array2?.RESIDEX?.ST?.sell || 0) -
                                      (array2?.RESIDEX?.ST?.buy || 0) >=
                                    0
                                      ? "text-green-900"
                                      : "text-red-900"
                                  } ${
                                    animate4 === 3 ? "slide-animation" : ""
                                  } relative px-3 py-1 font-semibold text-green-900 leading-tight flex items-center justify-center`}
                                >
                                  <span
                                    aria-hidden
                                    className={`${
                                      (array2?.RESIDEX?.ST?.sell || 0) -
                                        (array2?.RESIDEX?.ST?.buy || 0) >=
                                      0
                                        ? "text-green-900 bg-green-200"
                                        : "text-red-900 bg-red-200"
                                    } absolute inset-0 opacity-50 rounded-full`}
                                  ></span>
                                  <FaIndianRupeeSign className="relative text-xs" />
                                  <span className="relative">
                                    {array2?.RESIDEX?.ST?.sell > 0
                                      ? (
                                          (array2?.RESIDEX?.ST?.sell || 0) -
                                          (array2?.RESIDEX?.ST?.buy || 0)
                                        ).toLocaleString("en-IN")
                                      : 0}
                                  </span>
                                </span>
                              </td>
                              <td
                                onClick={() => toggleView("residex", "ST")}
                                className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm cursor-pointer"
                              >
                                <span className="flex items-center justify-center">
                                  <img
                                    src="/images/details.svg"
                                    alt=""
                                    className="w-8 h-8 hover:scale-95 duration-300 animate-pulse"
                                  />
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm min-w-[80px]">
                                <p className="text-gray-900 whitespace-no-wrap ">
                                  Long Term
                                </p>
                              </td>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                                <p
                                  className={`${
                                    animate3 === 2 ? "slide-animation" : ""
                                  } text-gray-900 whitespace-no-wrap text-center flex items-center justify-center`}
                                >
                                  <FaIndianRupeeSign className="text-black text-xs" />{" "}
                                  {residexLTSell.toLocaleString("en-IN") || 0}
                                </p>
                              </td>
                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                                <p
                                  className={`${
                                    animate3 === 2 ? "slide-animation" : ""
                                  } text-gray-900 whitespace-no-wrap text-center flex items-center justify-center`}
                                >
                                  <FaIndianRupeeSign className="text-black text-xs" />{" "}
                                  0
                                </p>
                              </td>

                              <td className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm animate-slideIn">
                                <p
                                  className={`${
                                    animate3 === 2 ? "slide-animation" : ""
                                  } text-gray-900 whitespace-no-wrap text-center`}
                                >
                                  12.5%
                                </p>
                              </td>
                              <td className="px-2 py-3 border-b border-gray-200 bg-white text-[13px] md:text-sm flex items-center justify-center gap-2">
                                <span
                                  className={`relative text-xs ${
                                    (array2?.RESIDEX?.LT?.sell || 0) -
                                      (array2?.RESIDEX?.LT?.buy || 0) >=
                                    0
                                      ? "text-green-900"
                                      : "text-red-900"
                                  } ${
                                    animate3 === 2 ? "slide-animation" : ""
                                  } relative px-3 py-1 font-semibold text-green-900 leading-tight flex items-center justify-center`}
                                >
                                  <span
                                    aria-hidden
                                    className={`${
                                      (array2?.RESIDEX?.LT?.sell || 0) -
                                        (array2?.RESIDEX?.LT?.buy || 0) >=
                                      0
                                        ? "text-green-900 bg-green-200"
                                        : "text-red-900 bg-red-200"
                                    } absolute inset-0 opacity-50 rounded-full`}
                                  ></span>
                                  <FaIndianRupeeSign className="relative text-xs" />

                                  <span className="relative">
                                    {array2?.RESIDEX?.LT?.sell > 0
                                      ? (
                                          (array2?.RESIDEX?.LT?.sell || 0) -
                                          (array2?.RESIDEX?.LT?.buy || 0)
                                        ).toLocaleString("en-IN")
                                      : 0}
                                  </span>
                                </span>
                              </td>
                              <td
                                onClick={() => toggleView("residex", "LT")}
                                className="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm cursor-pointer"
                              >
                                <span className="flex items-center justify-center">
                                  <img
                                    src="/images/details.svg"
                                    alt=""
                                    className="w-8 h-8 hover:scale-95 duration-300 animate-pulse"
                                  />
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {view !== "" && (
                  <div className="py-6 demo2-container flex justify-center">
                    <div className="inline-block w-[95%] md:w-[70%] xl:w-[40%] shadow-md rounded-lg border overflow-hidden bg-white pop-up-animation">
                      {/* Header */}
                      <div className="flex items-center justify-between px-3 py-[10px] bg-gray-100 border-b">
                        <p className="font-semibold text-black flex items-center gap-2">
                          <img
                            src="/images/details.svg"
                            alt=""
                            className="w-6 h-6"
                          />
                          Detailed Breakups
                        </p>
                        <IoIosCloseCircle
                          className="cursor-pointer text-2xl text-red-700"
                          onClick={() => setView("")}
                        />
                      </div>
                      <div className="p-3 overflow-x-auto">
                        {/* Table Header */}
                        <div
                          className={`${
                            view === "residex" ? "bg-[#009688]" : "bg-[#0590F8]"
                          }  text-white font-semibold text-sm flex rounded-t-lg min-w-[550px]`}
                        >
                          <div className="w-1/3 py-2 px-4 border-b border-gray-200">
                            Series
                          </div>
                          <div className="w-1/3 py-2 px-4 border-b border-gray-200 text-center">
                            Net Sold
                          </div>
                          <div className="w-1/2 py-2 px-4 border-b border-gray-200 text-center">
                            Acquisition Price
                          </div>
                          <div className="w-1/3 py-2 px-4 border-b border-gray-200 text-center">
                            Time Held
                          </div>
                        </div>

                        {/* Data Rows */}
                        <div className="max-h-[300px] overflow-y-auto text-sm min-w-[550px]">
                          {array?.[view.toUpperCase()]?.[term] &&
                          Object.keys(array?.[view.toUpperCase()]?.[term])
                            .length > 0 ? (
                            Object.entries(
                              array?.[view.toUpperCase()][term]
                            ).map(([security, data]) => (
                              <div
                                key={security}
                                className="flex items-center border-b border-x border-gray-200"
                              >
                                <div className="w-1/3 py-2 px-4">
                                  <p className="text-gray-900 whitespace-nowrap">
                                    {security}
                                  </p>
                                </div>
                                <div className="w-1/3 py-2 px-4 text-center">
                                  <p className="text-gray-900">
                                    {data?.sold.toLocaleString("en-IN") || 0}
                                  </p>
                                </div>
                                <div className="w-1/2 py-2 px-4 text-center flex items-center justify-center gap-1">
                                  <FaIndianRupeeSign className="text-xs" />
                                  {data?.acqu?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }) || 0.0}
                                </div>
                                <div className="w-1/3 py-2 px-4 text-center">
                                  {data?.days.toLocaleString("en-IN") || 0}{" "}
                                  months
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="text-center py-5">
                              <p>No Transactions yet</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {showModal && (
        <AlertRed
          message="Please Select a valid financial year and quarter"
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}

export default CapitalGain;
