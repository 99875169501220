import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./EventScheduler.css";
import { IoClose } from "react-icons/io5";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import Loader from "../components/Loaders/Loader1.jsx";

const EventScheduler = ({ adminCalender }) => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedBlocker, setEditedBlocker] = useState("Blocker text here...");
  const [selectedRate, setSelectedRate] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [showTitle, setShowTitle] = useState(null);
  const [assignees, setAssignees] = useState([]);
  // filter functions
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [isFilterOpen1, setIsFilterOpen1] = useState(false);
  const [assignee, setAssignee] = useState("All");
  const [status, setStatus] = useState("All");
  const [loader, setLoader] = useState(false);
  const rowsPerPage = 7;

  useEffect(() => {
    setLoader(true);
    const eventsOnDate = adminCalender
      .filter(
        (event) =>
          !event.National_holidays &&
          event.title !== "Next Coupon Payment Dates"
      )
      .map((event) => ({
        event_date: event.Date,
        title: event.title,
        assignee: event.Assignee,
        desc: event.Description,
        blocker: event.Blocker,
        rating: event.Rating,
        priority: event.Priority,
        status: event.Status,
      }))
      .sort((a, b) => new Date(a.event_date) - new Date(b.event_date));

    setAssignees([...new Set(eventsOnDate.map((event) => event.assignee))]);
    const filteredEvents = eventsOnDate.filter((event) => {
      const assigneeMatch = assignee === "All" || event.assignee === assignee;
      const statusMatch = status === "All" || event.status === status;
      return assigneeMatch && statusMatch;
    });
    setEvents(filteredEvents);

    // Calculate default page based on today's date or upcoming event
    const today = new Date();
    const firstUpcomingEventIndex = filteredEvents.findIndex(
      (event) => new Date(event.event_date) >= today
    );
    if (firstUpcomingEventIndex !== -1) {
      const defaultPage = Math.ceil(
        (firstUpcomingEventIndex + 1) / rowsPerPage
      );
      setCurrentPage(defaultPage);
    } else {
      setCurrentPage(1);
    }
    setLoader(false);
  }, [adminCalender, assignee, status]);

  const handleDeleteEvent = (val) => {
    fetch(`https://api-scdndestates.com/api/delete-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        val,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message === "Success") {
          window.location.reload();
        }
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  };

  const handleEvent = (title, key, value) => {
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.title === title ? { ...event, [key.toLowerCase()]: value } : event
      )
    );
    fetch(`https://api-scdndestates.com/api/edit-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        key,
        value,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete event/reminder");
        }
        return response.json();
      })
      .then((response) => {})
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(events.length / rowsPerPage)) {
      setCurrentPage(page);
    }
  };

  function formattedDate(dateString) {
    const date = new Date(dateString);
    const monthShortName = date.toLocaleString("en-IN", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
    return `${day}-${monthShortName}-${year}`;
  }

  const renderRows = () => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return events.length > 0 ? (
      events.slice(start, end).map((event, index) =>
        loader ? (
          <tr className="relative left-[15%] md:left-[42%] md:-translate-[50%]">
            <Loader />
          </tr>
        ) : (
          <tr
            key={index}
            className={`w-full border-b border-gray-200 hover:bg-slate-100 transition duration-200 cursor-pointer text-[#313131] font-semibold text-sm ${
              isAnimating ? "" : "table-fade-in"
            }`}
          >
            <td className="p-2 w-[13%] md:w-[9%]">
              <div className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none cursor-not-allowed">
                {formattedDate(event.event_date)}
              </div>
            </td>
            <td
              className="p-2 w-[30%]"
              onClick={() =>
                handleRowClick(
                  event.desc,
                  event.blocker,
                  event.rating,
                  event.title
                )
              }
            >
              <div
                onClick={() => setShowTitle(event.title)}
                className="min-w-[200px] w-full bg-gray-50 p-1.5 border border-gray-300 rounded ring-2 ring-[#cbefff] focus:outline-none"
              >
                {event.title.split(" ").slice(0, 7).join(" ")}
                {event.title.split(" ").length > 7 && " ..."}
              </div>
            </td>
            <td className="p-2 w-[18%] md:w-[11%]">
              <div
                type="text"
                className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none cursor-not-allowed"
              >
                {event.assignee}
              </div>
            </td>
            <td className="p-2 min-w-[80px] w-[7%]">
              <div className="flex w-full ">
                <div className="w-4/12 flex items-center justify-center">
                  <div
                    className={`${
                      event.priority === "P0"
                        ? "bg-[#bae6fd]"
                        : event.priority === "P1"
                        ? "bg-[#38bdf8]"
                        : event.priority === "P2"
                        ? "bg-[#0369a1]"
                        : event.priority === "P3"
                        ? "bg-[#172554]"
                        : "bg-[#bae6fd]"
                    } w-3 h-3 rounded-full `}
                  ></div>
                </div>
                <select
                  value={event.priority || "P0"}
                  onChange={(e) =>
                    handleEvent(event.title, "Priority", e.target.value)
                  }
                  className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                >
                  <option>P0</option>
                  <option>P1</option>
                  <option>P2</option>
                  <option>P3</option>
                </select>
              </div>
            </td>
            <td className="p-2 min-w-[140px] w-[7%]">
              <select
                value={event.status || "Not Started"}
                onChange={(e) =>
                  handleEvent(event.title, "Status", e.target.value)
                }
                className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
              >
                <option>Not Started</option>
                <option>In Progress</option>
                <option>Require Changes</option>
                <option>Pending</option>
                <option>Postponed</option>
                <option>Completed</option>
              </select>
            </td>
            <td className="p-2 w-[5%] ">
              <div className="flex items-center justify-center">
                <IoClose
                  className="p-1 bg-slate-50 rounded-full text-red-600 hover:bg-red-600 hover:text-white hover:scale-90  duration-300 cursor-pointer"
                  size={24}
                  onClick={() => handleDeleteEvent(event.title)}
                />
              </div>
            </td>
          </tr>
        )
      )
    ) : (
      <div className=" text-sm md:text-base">No Events Scheduled yet...</div>
    );
  };

  const handleRowClick = (desc, blocker, rating, title) => {
    setSelectedDesc(desc);
    setEditedBlocker(blocker);
    setSelectedRate(rating);
    setSelectedTitle(title);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const toggleFilter = () => setIsFilterOpen(!isFilterOpen);
  // use effect for filter close
  const filterRef = useRef(null); // Use a ref to reference the filter dropdown

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false); // Close the filter dropdown if the click is outside of it
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="w-full mx-auto shadow-[0px_1px_4px_1px_#00000024] bg-sky-600 rounded-lg relative">
      {/* Title and Pagination Control */}
      <div className="flex justify-between items-center py-2.5 px-2 md:px-3">
        <h1 className="text-base md:text-lg font-bold text-[#ffffff]">
          Sprint
        </h1>
        <div className="flex gap-5 items-center relative">
          <div ref={filterRef} className="relative z-[10]  max-md:hidden">
            {/* Increased z-index here */}
            <button
              onClick={toggleFilter}
              className="flex bg-sky-100 hover:bg-[#BAE6FC] gap-1 pl-1 py-1 pr-2 rounded text-sm font-semibold items-center hover:scale-95 duration-300"
            >
              <span className="rotate-90">
                <svg
                  id="fi_9702724"
                  className="w-4 h-4 text-black"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsSvg="http://www.w3.org/2000/svg"
                >
                  <g
                    id="layer1"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="4.1"
                    transform="translate(-300 -100)"
                  >
                    <path
                      id="path6012"
                      d="m321 106.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-13.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h13.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h3.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-3.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                    <path
                      id="path6014"
                      d="m311 116.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-3.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h3.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h13.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-13.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                  </g>
                </svg>
              </span>
              Filter
            </button>
            {isFilterOpen && (
              <div className="alert-animation absolute right-0 z-[50] mt-2 w-full bg-white border border-gray-200 rounded-md shadow-lg min-w-[200px] ">
                <div className="p-2.5 space-y-3 z-20">
                  {/* Assignee Dropdown */}
                  <div>
                    <label className="block text-sm font-semibold text-gray-800">
                      Assignee
                    </label>
                    <select
                      value={assignee}
                      onChange={(e) => {
                        setAssignee(e.target.value);
                        // Close the filter dropdown only if a valid selection is made (e.g., not empty or null)
                        if (e.target.value) {
                          setIsFilterOpen(false);
                        }
                      }}
                      className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="All">All</option>
                      {assignees.map((a) => (
                        <option key={a} value={a}>
                          {a}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Status Dropdown */}
                  <div>
                    <label className="block text-sm font-semibold text-gray-800">
                      Status
                    </label>
                    <select
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                        if (e.target.value) {
                          setIsFilterOpen(false);
                        }
                      }}
                      className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="All">All</option>
                      <option value="Require Changes">Require Changes</option>
                      <option value="Pending">Pending</option>
                      <option value="Pospond">Pospond</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Side bar */}
          <div className="md:hidden pl-[120px]">
            {/* Increased z-index here */}
            <button
              onClick={() => setIsFilterOpen1(true)}
              className="flex bg-sky-100 hover:bg-[#BAE6FC] gap-1 p-1 rounded text-sm font-semibold items-center hover:scale-95 duration-300"
            >
              <span className="rotate-90">
                <svg
                  id="fi_9702724"
                  className="w-4 h-4 text-black"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsSvg="http://www.w3.org/2000/svg"
                >
                  <g
                    id="layer1"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="4.1"
                    transform="translate(-300 -100)"
                  >
                    <path
                      id="path6012"
                      d="m321 106.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-13.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h13.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h3.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-3.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                    <path
                      id="path6014"
                      d="m311 116.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-3.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h3.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h13.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-13.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                  </g>
                </svg>
              </span>
            </button>
          </div>
          <div className="max-md:hidden md:flex space-x-4 items-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
                currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Previous
            </button>
            <span className="text-[#ffffff] text-sm">
              <span className="font-semibold">Page {currentPage}</span> of{" "}
              {Math.ceil(events.length / rowsPerPage)}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(events.length / rowsPerPage)}
              className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
                currentPage === Math.ceil(events.length / rowsPerPage)
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              Next
            </button>
          </div>
        </div>
        <div className="max-md:flex md:hidden space-x-4 items-center">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <MdSkipPrevious />
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === Math.ceil(events.length / rowsPerPage)}
            className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
              currentPage === Math.ceil(events.length / rowsPerPage)
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            <MdSkipNext />
          </button>
        </div>
      </div>

      {/* Event Table */}
      <div className="w-full overflow-scroll bg-white shadow-md">
        <table className="min-w-[800px] w-full">
          <thead>
            <tr className="bg-sky-100 text-[#212121] text-sm uppercase leading-normal">
              <th className="p-2.5 text-left">Date</th>
              <th className="p-2.5 text-left">Task</th>
              <th className="p-2.5 text-left">Assignee</th>
              <th className="p-2.5 text-left">Priority</th>
              <th className="p-2.5 text-left">Status</th>
              <th className="p-2.5 text-left">Delete</th>
            </tr>
          </thead>
          <tbody className={`${isAnimating ? "" : "table-fade-in"}`}>
            {renderRows()}
          </tbody>
        </table>
      </div>

      {/* Side bar option */}
      <div
        className={`md:hidden absolute -left-5 -top-5 h-screen z-[5] mt-2 w-full bg-white border border-gray-200 rounded-md  max-w-[260px] transition-transform duration-500 ease-in-out ${
          isFilterOpen1 ? "translate-x-0 shadow-lg" : "-translate-x-full"
        }`}
      >
        <div className="p-4 space-y-3 z-20">
          <div className="flex items-center justify-between">
            <p className="text-[15px] font-bold text-black">Filter</p>

            <div
              onClick={() => setIsFilterOpen1(false)}
              className=" flex justify-end p-0"
            >
              <button
                className="circleadmin"
                data-animation="showShadow"
                data-remove="3000"
              ></button>
            </div>
          </div>
          <hr />
          {/* Assignee Dropdown */}
          <div>
            <label className="block text-sm font-semibold text-gray-800">
              Assignee
            </label>
            <select
              value={assignee}
              onChange={(e) => {
                setAssignee(e.target.value);
                if (e.target.value) {
                  setIsFilterOpen1(false);
                }
              }}
              className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="All">All</option>
              {assignees.map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </select>
          </div>

          {/* Status Dropdown */}
          <div>
            <label className="block text-sm font-semibold text-gray-800">
              Status
            </label>
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                if (e.target.value) {
                  setIsFilterOpen1(false);
                }
              }}
              className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="All">All</option>
              <option value="Require Changes">Require Changes</option>
              <option value="Pending">Pending</option>
              <option value="Pospond">Pospond</option>
            </select>
          </div>
        </div>
      </div>

      {/* Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 ">
          <div className="bg-white  rounded-lg w-[90%] md:min-w-[600px]  max-w-md pop-up-animation">
            <div className="flex justify-between px-4 py-2.5 border-b-2 shadow-md">
              {" "}
              <h2 className="text-base md:text-lg font-semibold text-black flex items-center gap-2">
                <span className="">
                <svg id="fi_18401969" className="w-6 h-6"   viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m2.55 13.33v43.18a5.5 5.5 0 0 0 5.5 5.49h47.9a5.5 5.5 0 0 0 5.5-5.49v-43.18a5.5 5.5 0 0 0 -5.5-5.49h-2.8v-1.47a4.4 4.4 0 0 0 -1.29-3.09 4.363 4.363 0 0 0 -7.45 3.09v1.47h-24.82v-1.47a4.37 4.37 0 1 0 -8.74 0v1.47h-2.8a5.5 5.5 0 0 0 -5.5 5.49zm43.86-6.96a2.375 2.375 0 0 1 2.37-2.37 2.338 2.338 0 0 1 1.66.69 2.372 2.372 0 0 1 .71 1.68v5.97a2.37 2.37 0 1 1 -4.74 0zm-33.56 0a2.369 2.369 0 0 1 4.04-1.68 2.4 2.4 0 0 1 .7 1.68v5.97a2.37 2.37 0 1 1 -4.74 0zm-8.3 17.02h54.9v33.12a3.5 3.5 0 0 1 -3.5 3.49h-47.9a3.5 3.5 0 0 1 -3.5-3.49z"></path><path d="m15.78 34.8a5.15 5.15 0 1 0 -5.16-5.15 5.155 5.155 0 0 0 5.16 5.15zm0-8.3a3.15 3.15 0 1 1 -3.16 3.15 3.156 3.156 0 0 1 3.16-3.15z"></path><path d="m32 34.8a5.15 5.15 0 1 0 -5.15-5.15 5.155 5.155 0 0 0 5.15 5.15zm0-8.3a3.15 3.15 0 1 1 -3.15 3.15 3.15 3.15 0 0 1 3.15-3.15z"></path><path d="m48.22 34.8a5.15 5.15 0 1 0 -5.15-5.15 5.158 5.158 0 0 0 5.15 5.15zm0-8.3a3.15 3.15 0 1 1 -3.15 3.15 3.158 3.158 0 0 1 3.15-3.15z"></path><path d="m15.78 46.62a5.155 5.155 0 1 0 -5.16-5.15 5.155 5.155 0 0 0 5.16 5.15zm0-8.31a3.155 3.155 0 1 1 -3.16 3.16 3.165 3.165 0 0 1 3.16-3.16z"></path><path d="m32 46.62a5.155 5.155 0 1 0 -5.15-5.15 5.155 5.155 0 0 0 5.15 5.15zm0-8.31a3.155 3.155 0 1 1 -3.15 3.16 3.158 3.158 0 0 1 3.15-3.16z"></path><path d="m48.22 46.62a5.155 5.155 0 1 0 -5.15-5.15 5.158 5.158 0 0 0 5.15 5.15zm0-8.31a3.155 3.155 0 1 1 -3.15 3.16 3.167 3.167 0 0 1 3.15-3.16z"></path><path d="m15.78 58.43a5.15 5.15 0 1 0 -5.16-5.15 5.155 5.155 0 0 0 5.16 5.15zm0-8.3a3.15 3.15 0 1 1 -3.16 3.15 3.156 3.156 0 0 1 3.16-3.15z"></path><path d="m32 58.43a5.15 5.15 0 1 0 -5.15-5.15 5.155 5.155 0 0 0 5.15 5.15zm0-8.3a3.15 3.15 0 1 1 -3.15 3.15 3.15 3.15 0 0 1 3.15-3.15z"></path></svg>
                </span>
                Event Details
              </h2>
              <div
              onClick={() => closePopup(false)}
              className=" flex justify-end  p-0 m-0 rounded-full"
            >
              <button
                className="circleadmin bg-white"
                data-animation="showShadow"
                data-remove="3000"
              ></button>
            </div>
             
            </div>
            <div className="p-6 bg-white text-sm md:text-base rounded-b-lg">
              <div className="flex flex-col mb-2">
                <span className="font-semibold text-black">Title:</span>
                <span className="border rounded p-1 font-medium ">
                  {showTitle}
                </span>
              </div>
              <div className="mb-2">
                <div className="flex justify-between items-center mb-1">
                  <span className="font-semibold text-black">Description:</span>
                  {/* <button className="text-blue-500 hover:underline">Edit</button> */}
                </div>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded min-h-[200px]"
                  defaultValue={selectedDesc || "Description text here..."}
                  rows="3"
                  readOnly
                />
              </div>
              <div>
                <span className="flex font-semibold text-black">
                  Blockers (if any):
                </span>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Why this project could not be finished in time..."
                  value={editedBlocker} // Show the editing state value
                  onChange={(e) => setEditedBlocker(e.target.value)} // Update the editedBlocker state
                  rows="3"
                  readOnly={!isEditing}
                />
              </div>

              <div className="flex justify-end mt-1">
                {isEditing ? (
                  <button
                    className="px-2 py-1 bg-sky-400 text-white text-sm rounded hover:bg-sky-500 duration-300"
                    onClick={(e) => {
                      setIsEditing(false);
                      handleEvent(selectedTitle, "Blocker", editedBlocker);
                      setShowPopup(false);
                    }}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="px-2 py-1 bg-sky-400 text-white font-semibold text-sm rounded hover:bg-sky-500 duration-300"
                    onClick={(e) => {
                      setIsEditing(true);
                      setEditedBlocker("");
                    }}
                  >
                    Edit
                  </button>
                )}
              </div>
              <div className="mt-3">
                <span className="font-semibold text-black flex">
                  Work Duration:{" "}
                </span>
                <select className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300 mt-1">
                  <option value="Select">Select</option>
                  <option value="2 hours">2 hours</option>
                  <option value="4 hours">4 hours</option>
                  <option value="1 day">1 day</option>
                  <option value="1.5 day">1.5 day</option>
                  <option value="2 days">2 days</option>
                  <option value="3 dasy">3 days</option>
                  <option value="4 dasy">4 days</option>
                  <option value="1 weeks">1 weeks</option>
                  <option value="2 weeks">2 weeks</option>
                </select>
              </div>
              <div>
                <div className="flex justify-between items-center mb-1 mt-2">
                  <span className="font-semibold text-black">Rating:</span>
                </div>

                <select
                  value={selectedRate || "Select"}
                  onChange={(e) => {
                    handleEvent(selectedTitle, "Rating", e.target.value);
                    setSelectedRate(e.target.value);
                  }}
                  className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                >
                  <option value="Select">Select</option>
                  <option value="Exceeds Expectation">
                    Exceeds Expectation
                  </option>
                  <option value="Meets Expectation">Meets Expectation</option>
                  <option value="Not Meeting Expectation">
                    Not Meeting Expectation
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventScheduler;
