import React, { useEffect, useState } from "react";
import CheckAuth from "../components/ServiceFunctions/CheckAuth.jsx";
import "../pages/Ownership/Ownership.css";
import secureLocalStorage from "react-secure-storage";
import { PiHandCoinsFill } from "react-icons/pi";
import { HiCurrencyRupee } from "react-icons/hi";
import PieChart from "./PieChart.jsx";

function AssetLiabilityMatch() {
  const { pan, cash, walr, residex } = CheckAuth();
  const [blockedBalancePMY, setBlockedBalancePMY] = useState();
  const [blockedBalanceP2P, setBlockedBalanceP2P] = useState();
  const [blockedUnit, setBlockedUnit] = useState([]);
  const [series, setSeries] = useState([]);
  const [cities, setCities] = useState([]);
  const [residexData, setResidexData] = useState([]);
  const [walrData, setWalrData] = useState([]);
  const storedData1 = secureLocalStorage.getItem("tempData");
  const tempData = JSON.parse(storedData1);
  const storedData = secureLocalStorage.getItem("ownershipData");
  const ownershipData = JSON.parse(storedData);
  const storedData3 = secureLocalStorage.getItem("blockchainData");
  const blockchainData = JSON.parse(storedData3);
  const [showBox, setShowBox] = useState("WALR");
  const [selectedTab, setSelectedTab] = useState("walr");

  const handleChange = (event) => {
    setSelectedTab(event.target.id);
  };

  const pieData = [
    { name: "WALR", value: Math.abs(walr), color: "#E90074" },
    {
      name: "RESIDEX",
      value: residex,
      color: "#071952",
    },
    { name: "Long Term Options", value: 0, color: "#FF9A00" },
  ];

  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/primary-bid-box/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setSeries(data.data);
        setCities(data.data2);

        // Process E`
        if (storedData1) {
          let blocked = {};

          tempData.forEach((data) => {
            if (
              data.Order_id?.includes("PMY-SCD") &&
              data.Security?.includes(selectedTab.toUpperCase())
            ) {
              let key = `${data.Security}-${data.Price_per_unit}`;

              if (!blocked[key]) {
                blocked[key] = {
                  Security: data.Security,
                  Price_per_unit: data.Price_per_unit,
                  Units: 0,
                };
              }

              if (data.Buyer === "AAXCS0834Q") {
                blocked[key].Units += data.Units;
              } else if (data.Seller === "AAXCS0834Q") {
                blocked[key].Units -= data.Units;
              }
            }
          });

          // Convert the dictionary back to an array
          let blockedArray = Object.values(blocked);
          setBlockedUnit(blockedArray);
        }
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });

    // Read pie chart card data
    fetch(`https://api-scdndestates.com/api/read-custodian-values/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to logout");
        }
        return response.json();
      })
      .then((response) => {
        setWalrData(response.data);
        setResidexData(response.data2);
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  }, [pan, selectedTab]);

  useEffect(() => {
    if (pan) {
      // Process F
      let blockedPMY = 0;
      let blockedP2P = 0;
      if (storedData1) {
        tempData.map((data) => {
          if (
            data.Buyer === pan &&
            (data.Status === "Processing" || data.Status === "Revised")
          ) {
            let b = data.Units * data.Price_per_unit;
            blockedPMY = blockedPMY + b;
          }
          if (
            data.Buyer === pan &&
            (data.Status === "Pending" ||
              data.Status === "Modified" ||
              data.Status === "Executed")
          ) {
            let b = data.Units * data.Price_per_unit;
            blockedP2P = blockedP2P + b;
          }
        });
        // Process F`
      }
      setBlockedBalancePMY(blockedPMY);
      setBlockedBalanceP2P(blockedP2P);
    }
  }, [pan, blockedBalancePMY, blockedBalanceP2P]);

  const handleShowFunction = (name) => {
    setShowBox(name);
  };

  function getWALRNextPayment() {
    let sum = 0;
    Object.keys(ownershipData.WALR).forEach((quarter) => {
      const seriesEntry = series.find((item) => item[quarter]);
      if (seriesEntry) {
        if (ownershipData.WALR[quarter].CoverYes !== undefined) {
          const couponAtIssue =
            (seriesEntry[quarter]["Coupon post reset"] *
              Math.abs(ownershipData.WALR[quarter].CoverYes) *
              10) /
            4;
          sum += couponAtIssue;
        }
        if (ownershipData.WALR[quarter].CoverNo !== undefined) {
          const lastSeriesItem = series[series.length - 1];
          const lastSeriesKey = Object.keys(lastSeriesItem)[0];
          const couponAtIssue =
            (lastSeriesItem[lastSeriesKey]["Coupon at Issue"] *
              Math.abs(ownershipData.WALR[quarter].CoverNo) *
              10) /
            4;
          sum += couponAtIssue;
        }
      }
    });
    return sum;
  }

  function getRESIDEXNextPayment() {
    let sum = 0;
    Object.keys(ownershipData.RESIDEX).forEach((city) => {
      const seriesEntry = series.find((item) => item[city]);
      if (seriesEntry) {
        if (ownershipData.RESIDEX[city].Index !== undefined) {
          const couponAtIssue =
            seriesEntry[city]["Price"] *
            0.03 *
            Math.abs(ownershipData.RESIDEX[city].Index);
          sum += couponAtIssue;
        }
        if (ownershipData.RESIDEX[city].Futures !== undefined) {
          sum += 0;
        }
      }
    });
    return sum;
  }

  function getExcessInterest() {
    let sum = 0;
    Object.keys(ownershipData.WALR).forEach((quarter) => {
      const seriesEntry = series.find((item) => item[quarter]);
      if (seriesEntry) {
        sum += seriesEntry[quarter]["Total Interest"];
      }
    });
    return sum;
  }

  function getTotalInterest() {
    let investedValueResidex = 0;
    const startDate = new Date("04-01-2019");
    blockchainData.forEach((item) => {
      const itemDate = new Date(item.Timestamp);
      const endDate = new Date();
      if (itemDate >= startDate && itemDate <= endDate) {
        if (item.Buyer === pan && item.Security.startsWith("RESIDEX")) {
          investedValueResidex += item.Units * item.Price_per_unit;
        } else if (item.Seller === pan && item.Security.startsWith("RESIDEX")) {
          investedValueResidex -= item.Units * item.Price_per_unit;
        }
      }
    });

    let sum = 0;
    var nextPayment = 0;
    Object.keys(ownershipData.RESIDEX).forEach((city) => {
      const seriesEntry = series.find((item) => item[city]);
      if (seriesEntry) {
        if (ownershipData.RESIDEX[city].Index !== undefined) {
          nextPayment =
            seriesEntry[city]["Price"] * ownershipData.RESIDEX[city].Index;
          sum += nextPayment;
        }
        if (ownershipData.RESIDEX[city].Futures !== undefined) {
          nextPayment = investedValueResidex;
          sum += nextPayment;
        }
      }
    });
    return sum;
  }

  function getTotalCover() {
    var sum = 0;
    blockchainData.filter((item, index) => {
      if (item.Buyer === pan && item["Security"].slice(0, 4) === "WALR") {
        sum += item["Units"] * item["Price_per_unit"];
      }
    });
    blockchainData.filter((item, index) => {
      if (item.Seller === pan && item["Security"].slice(0, 4) === "WALR") {
        sum -= item["Units"] * item["Price_per_unit"];
      }
    });
    return sum - walr;
  }

  function getTotalPremium() {
    let sum = 0;
    Object.keys(ownershipData.RESIDEX).forEach((city) => {
      const seriesEntry = series.find((item) => item[city]);
      if (seriesEntry) {
        if (ownershipData.RESIDEX[city].Futures !== undefined) {
          const prod =
            Math.abs(ownershipData.RESIDEX[city].Futures) *
            seriesEntry[city]["3 mth premium"] *
            1000;
          sum += prod;
        }
      }
    });
    return sum;
  }

  return (
    <div className="max-md:flex-col md:flex ">
      {/* Left Section */}
      <div className="w-full md:w-[39%] flex flex-col max-w-[1800px] mx-auto border p-2 rounded-lg bg-white">
        <div className="">
          <div className="flex justify-center items-center">
            <PieChart data={pieData} showFunction={handleShowFunction} />
          </div>
        </div>

        <div className="px-3 py-4 border relative mt-5 bg-light rounded-lg mx-2">
          <h2 className="absolute -top-4 bg-white border rounded-lg px-2 py-1.5 font-bold text-black">
            Liquidity Position
          </h2>
          {/* feedback Section */}
          <div className="md:flex flex-wrap gap-1.5 justify-between items-center mt-2">
            <div className="bg-[#3B71CA] max-md:mt-2 border-2 border-[#3B71CA] text-center py-2 rounded-md w-full  2xl:w-[32%] ">
              <div>
                <p className="font-semibold text-white text-sm 2xl:text-xs  mt-1">
                  Cash
                </p>
                <div className="mt-1">
                  <div className="flex items-center font-semibold gap-1 justify-center">
                    <HiCurrencyRupee size={20} color="orange" />
                    <span className="text-base text-light">
                      {cash?.toLocaleString("en-IN")}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#3B71CA] max-md:mt-2 border-2 border-[#3B71CA] text-center py-2 rounded-md w-full xl:w-[49%] 2xl:w-[32%]">
              <div>
                <p className="font-semibold text-white text-sm 2xl:text-xs  mt-1">
                  Blocked for Auction
                </p>
                <div className="mt-1">
                  <div className="flex items-center font-semibold gap-1 justify-center">
                    <HiCurrencyRupee size={20} color="orange" />
                    <span className="text-base text-light">
                      {blockedBalancePMY?.toLocaleString("en-IN")}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#3B71CA] max-md:mt-2 border-2 border-[#3B71CA] text-center py-2 rounded-md w-full xl:w-[49%] 2xl:w-[32%]">
              <div>
                <p className="font-semibold text-white text-sm 2xl:text-xs  mt-1">
                  Blocked for P-2-P
                </p>
                <div className="mt-1">
                  <div className="flex items-center font-semibold gap-1 justify-center">
                    <HiCurrencyRupee size={20} color="orange" />
                    <span className="text-base text-light">
                      {blockedBalanceP2P?.toLocaleString("en-IN")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="xl:flex gap-2 justify-between">
            <div className="w-full xl:w-[49%] text-sm font-bold text-black mt-3 flex gap-2 border p-1 rounded-lg shadow-sm bg-white">
              <div className="p-2 bg-zinc-50 rounded-lg border">
                <img src="/images/icici-bank.svg" alt="" className="w-8" />
              </div>
              <div className="text-sm 2xl:text-base">
                <p>ICICI Balance</p>
                <p>
                  ₹{" "}
                  {residexData[4]?.["Cash"]?.["Cash"].toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>
            <div className="w-full xl:w-[49%] text-sm font-bold text-black mt-3 flex gap-2 border p-1 rounded-lg shadow-sm bg-white">
              <div className="p-2 bg-zinc-50 rounded-lg border">
                <img src="/images/bob-bank.svg" alt="" className="w-8" />
              </div>
              <div className="text-sm 2xl:text-base">
                <p>BOB Balance</p>
                <p>
                  ₹{" "}
                  {walrData[2]?.["Cash"]?.["Cash"].toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="w-full md:w-[60%]  mx-auto max-w-[1800px] border p-3 rounded-lg bg-white">
        {/* Top container */}
        <div className="flex flex-wrap gap-2 justify-between text-black">
          {/* Card 1 */}
          <div className="border rounded-lg px-2.5 py-1 w-full lg:w-[32%] xl:w-[30%] shadow-md slice-btn bg-white">
            <div className="flex items-center justify-between">
              <div
                className={`${
                  showBox === "WALR"
                    ? "bg-[#E90074]"
                    : showBox === "RESIDEX"
                    ? "bg-[#071952]"
                    : "bg-[#FF9A00]"
                } text-black rounded-md px-1 py-1`}
              >
                <PiHandCoinsFill color={"#fff"} className="w-10 h-12" />
              </div>
              <h2 className="text-sm lg:text-base text-end font-semibold my-3">
                Next <br />
                Payment
              </h2>
            </div>
            <hr />
            <p className="font-bold my-2 text-sm lg:text-sm xl:text-base p-1.5 rounded-lg ">
              {showBox === "WALR" ? (
                <>
                  &#x20B9;&nbsp;
                  {parseInt(getWALRNextPayment())?.toLocaleString("en-IN")}
                </>
              ) : showBox === "RESIDEX" ? (
                <>
                  &#x20B9;&nbsp;
                  {parseInt(getRESIDEXNextPayment())?.toLocaleString("en-IN")}
                </>
              ) : (
                <></>
              )}
            </p>
          </div>

          {/* Card 2 */}
          <div className="border rounded-lg px-2.5 py-1 w-full lg:w-[32%] xl:w-[30%] shadow-md slice-btn bg-white">
            <div className="flex items-center justify-between">
              <div
                className={`${
                  showBox === "WALR"
                    ? "bg-[#E90074]"
                    : showBox === "RESIDEX"
                    ? "bg-[#071952]"
                    : "bg-[#FF9A00]"
                } text-white rounded-md px-2.5 py-2.5`}
              >
                {showBox === "WALR" ? (
                  <>
                    <img
                      className="w-7 h-7"
                      src="/images/excess-icon.png"
                      alt=""
                    />
                  </>
                ) : showBox === "RESIDEX" ? (
                  <>
                    <img
                      className="w-7 h-7"
                      src="/images/gain-icon.png"
                      alt=""
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <h2 className="text-sm lg:text-base text-end font-semibold my-3">
                {showBox === "WALR" ? (
                  <>
                    Excess <br />
                    Interest
                  </>
                ) : showBox === "RESIDEX" ? (
                  <>
                    Unrealized <br />
                    Gain
                  </>
                ) : (
                  <></>
                )}
              </h2>
            </div>
            <hr />
            <p className="font-bold my-2 text-sm lg:text-sm xl:text-base bg-[#DBEDF9] p-1.5 rounded-lg">
              {showBox === "WALR" ? (
                <>
                  &#x20B9;&nbsp;
                  {parseFloat(getExcessInterest())?.toLocaleString("en-IN")}
                </>
              ) : showBox === "RESIDEX" ? (
                <>{parseFloat(getTotalInterest()).toFixed(2)} %</>
              ) : (
                <></>
              )}
            </p>
          </div>

          {/* Card 3 */}
          <div className="border rounded-lg px-2.5 py-1 w-full lg:w-[32%] xl:w-[30%] shadow-md slice-btn bg-white">
            <div className="flex items-center justify-between">
              <div
                className={`${
                  showBox === "WALR"
                    ? "bg-[#E90074]"
                    : showBox === "RESIDEX"
                    ? "bg-[#071952]"
                    : "bg-[#FF9A00]"
                } text-white rounded-md px-2.5 py-2.5`}
              >
                {showBox === "WALR" ? (
                  <>
                    <img
                      className="w-7 h-7"
                      src="/images/cover-icon.png"
                      alt=""
                    />
                  </>
                ) : showBox === "RESIDEX" ? (
                  <>
                    <img
                      className="w-7 h-7"
                      src="/images/premium-icon.png"
                      alt=""
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <h2 className="text-sm lg:text-base text-end font-semibold my-3">
                {showBox === "WALR" ? (
                  <>
                    Total <br /> Cover
                  </>
                ) : showBox === "RESIDEX" ? (
                  <>
                    3 month <br />
                    Premium
                  </>
                ) : (
                  <></>
                )}
              </h2>
            </div>
            <hr />
            <p className="font-bold my-2 text-sm lg:text-sm xl:text-base bg-[#DBEDF9] p-1.5 rounded-lg">
              {showBox === "WALR" ? (
                <>&#x20B9;&nbsp;{getTotalCover()?.toLocaleString("en-IN")}</>
              ) : showBox === "RESIDEX" ? (
                <>&#x20B9;&nbsp;{getTotalPremium()?.toLocaleString("en-IN")}</>
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>

        <div className="px-3 py-4 border relative mt-5 bg-light rounded-lg">
          <h2 className="absolute -top-4 bg-white border rounded-lg px-2 py-2 font-bold text-black">
            Blocked For Auction
          </h2>

          {/* Buttons */}
          <div className="flex justify-center mb-2">
            <div className="w-full md:w-fit flex justify-center">
              <div className="segmented-control relative flex items-center border shadow-sm rounded-lg w-52 h-8 px-1 text-sm xl:text-base">
                <input
                  type="radio"
                  name="radio2"
                  value="3"
                  id="walr"
                  className="hidden"
                  checked={selectedTab === "walr"}
                  onChange={handleChange}
                />
                <label
                  htmlFor="walr"
                  className="segmented-control__1 flex-1 flex justify-center items-center text-gray-600 transition-all duration-500 cursor-pointer"
                  style={{
                    width: "6rem",
                  }}
                >
                  <p className="m-0 font-bold">WALR</p>
                </label>

                <input
                  type="radio"
                  name="radio2"
                  value="4"
                  id="residex"
                  className="hidden"
                  checked={selectedTab === "residex"}
                  onChange={handleChange}
                />
                <label
                  htmlFor="residex"
                  className="segmented-control__2 flex-1 flex justify-center items-center text-gray-600 transition-all duration-500 cursor-pointer z-1"
                  style={{
                    width: "7.6rem",
                  }}
                >
                  <p className="m-0 font-bold">RESIDEX</p>
                </label>

                <div
                  className="absolute h-6 z-0 rounded-md bg-[#d8e5f969] shadow-inner pointer-events-none transition-transform duration-300 ease-in-out"
                  style={{
                    transform:
                      selectedTab === "walr"
                        ? "translateX(0)"
                        : selectedTab === "residex"
                        ? "translateX(6.3rem)"
                        : "translateX(13.6rem)",
                    width:
                      selectedTab === "walr"
                        ? "6rem"
                        : selectedTab === "residex"
                        ? "6rem"
                        : "0rem",
                  }}
                ></div>
              </div>
            </div>
          </div>
          {/* Table */}
          <div className="flex flex-col overflow-x-auto">
            <div className="w-full text-sm md:text-lg min-w-[500px]">
              <div className="bg-[#3B71CA] text-white rounded-t-lg px-2">
                <div className="flex flex-wrap items-center justify-around text-xs md:text-sm w-full rounded-t-lg">
                  <p
                    scope="col"
                    className="py-2 text-center font-semibold w-[30%]"
                  >
                    Security Name
                  </p>
                  <p
                    scope="col"
                    className="py-2 text-center font-semibold w-[23%]"
                  >
                    Units
                  </p>
                  <p
                    scope="col"
                    className="py-2 text-center font-semibold w-[23%]"
                  >
                    Price/Unit (₹)
                  </p>
                  <p
                    scope="col"
                    className="py-2 text-center font-semibold w-[23%]"
                  >
                    Action
                  </p>
                </div>
              </div>

              <div className="px-2 flex flex-col gap-2 border rounded-b-lg py-2 max-h-[340px] min-h-[340px] overflow-y-scroll">
                {blockedUnit.length > 0 ? (
                  blockedUnit.map((data, index) => (
                    <div
                      key={index}
                      className="border-b last:border-none border rounded-lg flex flex-wrap items-center justify-around hover:scale-105 duration-300 bg-[#ffffff] hover:bg-[#DBEAFE]"
                    >
                      <p className="text-center py-1.5 text-xs md:text-sm font-semibold w-[30%]">
                        {data.Security ? data.Security : ""}
                      </p>
                      <p className="text-center py-1.5 text-xs md:text-sm font-semibold w-[23%]">
                        {data.Units ? Math.abs(data.Units) : ""}
                      </p>
                      <p className="text-success text-center py-1.5 text-xs md:text-sm font-semibold w-[23%]">
                        {data.Price_per_unit
                          ? data.Price_per_unit.toLocaleString("en-In")
                          : 0}
                      </p>
                      <p className="text-center py-1.5 text-xs md:text-sm font-semibold w-[23%]">
                        <span
                          className={`${
                            data["Units"] < 0 ? "text-danger" : "text-success"
                          }`}
                        >
                          {data["Units"] < 0 ? "Redeem" : "Purchased"}
                        </span>
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="flex items-center justify-center py-3">
                    <p className="text-center text-xs md:text-sm font-semibold">
                      No Transactions Yet
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AssetLiabilityMatch;
